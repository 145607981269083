import React, { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { Route, Routes } from "react-router";

import GuestRoute from "helpers/authentication/guestRoute";
import AuthRoute from "helpers/authentication/authRoute";

import Layout from "./components/Layout/Layout";
import Dashboard from "views/dashboard";
import NotFound from "views/NotFound";
import Login from "views/login";
import Logout from "views/Logout";
import { getCurrentUser } from "store/actions";
import Loader from "./components/shared/Loader";

import UsersIndex from "views/users/Index";
import AddUser from "views/users/AddUser";
import EditUser from "views/users/EditUser";

import PackagesIndex from "views/packages/Index";
import AddPackage from "views/packages/AddPackage";
import EditPackage from "views/packages/EditPackage";

import CategoriesIndex from "views/categories/Index";
import AddCategory from "views/categories/AddCategory";
import EditCategory from "views/categories/EditCategory";

import FeaturesIndex from "views/features/Index";
import AddFeature from "views/features/AddFeature";
import EditFeature from "views/features/EditFeature";

import PaymentMethodsIndex from "views/paymentMethods/Index";
import AddPaymentMethod from "views/paymentMethods/AddPaymentMethod";
import EditPaymentMethod from "views/paymentMethods/EditPaymentMethod";
// ----------------------- themes ------------------------

import ThemesIndex from "views/themes/Index";
import AddTheme from "views/themes/AddTheme";
import EditTheme from "views/themes/EditTheme";
// ----------------------- themes ------------------------

import ThemesComponentsIndex from "views/themes/components/Index";
import AddThemesComponents from "views/themes/components/Add";
import EditThemesComponents from "views/themes/components/Edit";

import ThemesComponentsTypesIndex from "views/themes/componentTypes/Index";
import AddThemesComponentsType from "views/themes/componentTypes/Add";
import EditThemesComponentsType from "views/themes/componentTypes/Edit";

import ThemesPagesIndex from "views/themes/pages/Index";
import AddThemesPages from "views/themes/pages/Add";
import EditThemesPages from "views/themes/pages/Edit";

import ThemesTemplatesIndex from "views/themes/templates/Index";
import AddThemesTemplates from "views/themes/templates/Add";
import EditThemesTemplates from "views/themes/templates/Edit";

import ThemesTemplateComponentsIndex from "views/themes/templates/components/Index";
import AddThemesTemplateComponents from "views/themes/templates/components/Add";
import EditThemesTemplateComponents from "views/themes/templates/components/Edit";

// ----------------------- themes ------------------------

import CountriesIndex from "views/countries/Index";
import AddCountry from "views/countries/AddCountry";
import EditCountry from "views/countries/EditCountry";

import CitiesIndex from "views/cities/Index";
import AddCity from "views/cities/AddCity";
import EditCity from "views/cities/EditCity";

import AreasIndex from "views/areas/Index";
import AddArea from "views/areas/AddArea";
import EditArea from "views/areas/EditArea";

import ProductTypesInex from "views/productTypes/Index";
import AddProductType from "views/productTypes/AddProductType";
import EditProductType from "views/productTypes/EditProductType";

import LanguagesInex from "views/languages/Index";
import AddLanguage from "views/languages/AddLanguage";
import EditLanguage from "views/languages/EditLanguage";

import UnitsInex from "views/measuringUnits/Index";
import AddUnit from "views/measuringUnits/AddUnit";
import EditUnit from "views/measuringUnits/EditUnit";

import BanksInex from "views/banks/Index";
import AddBank from "views/banks/AddBank";
import EditBank from "views/banks/EditBank";

import CurrenciesInex from "views/currencies/Index";
import AddCurrency from "views/currencies/AddCurrency";
import EditCurrency from "views/currencies/EditCurrency";

// ----------------------- builder ------------------------
import BuilderPage from "views/builder";
import FontsIndex from "views/fonts/Index";
import AddFont from "views/fonts/AddFont";
import EditFont from "views/fonts/EditFont";
import PortalTechnicalSupport from "./views/portalTechnicalSupport/Index";
import AddTechnical from "./views/portalTechnicalSupport/Add";
import ShowTechnical from "views/portalTechnicalSupport/Show";
import TechnicalSupportHeading from "views/portalTechnicalSupport/Heading";
import FaqsHeading from "views/settings/portalSettings/portalFaqs/Heading";
import Settings from "views/settings";
import PortalHelpCategories from "views/settings/portalSettings/helpCategories/Index";
import AddHelpCategory from "views/settings/portalSettings/helpCategories/Add";
import EditHelpCategory from "views/settings/portalSettings/helpCategories/Edit";
import HelpBlogs from "views/settings/portalSettings/portalHelpBlogs/Index";
import AddHelpBlog from "views/settings/portalSettings/portalHelpBlogs/Add";
import EditHelpBlog from "views/settings/portalSettings/portalHelpBlogs/Edit";
import ShowHelpBlog from "views/settings/portalSettings/portalHelpBlogs/Show";
/* ******** BLOGS ***************/
import BlogsIndex from "views/blogs/Index";
import AddBlog from "views/blogs/AddBlog";
import EditBlog from "views/blogs/EditBlog";
import ShowBlog from "views/blogs/ShowBlog";
/* ******** BLOGS CATEGORIES ***************/
import BlogCategoriesIndex from "views/blogsCategories/Index";
import AddBlogCategory from "views/blogsCategories/AddBlogCategory";
import EditBlogCategory from "views/blogsCategories/EditBlogCategory";

/* ************** */
import Subscribers from "views/settings/portalSettings/subscribers/Index";
import Faqs from "views/settings/portalSettings/portalFaqs/Index";
import AddFaq from "views/settings/portalSettings/portalFaqs/Add";
import EditFaq from "views/settings/portalSettings/portalFaqs/Edit";
import PortalSections from "views/settings/portalSettings/sections/Index";
import AddSection from "views/settings/portalSettings/sections/Add";
import EditSection from "views/settings/portalSettings/sections/Edit";
import Heading from "views/blogs/Heading";
import SeoData from "views/SeoData";
import PortalReservations from "views/portalReservations/Index";
import ShowReservation from "views/portalReservations/Show";
import AddPermission from "views/permissions/Add";
import EditPermission from "views/permissions/Edit";
import AddRole from "views/permissions/AddRole";
import Users from "views/permissions/Users";
import EditRole from "views/permissions/EditRole";
import AddGroup from "views/permissions/AddGroup";
import EditGroup from "views/permissions/EditGroup";
// import RolesTable from "views/permissions/RolesTable";
import Roles from "views/permissions/Roles";
import SEORedirects from "views/settings/portalSettings/SEORedirects";
import AddSeoRedirect from "views/settings/portalSettings/SEORedirects/Add";
import EditSeoRedirect from "views/settings/portalSettings/SEORedirects/Edit";
// import InfoSettings from "views/settings/infoSettings";
// import Features from "views/settings/portalSettings/features/Index";

// import BlogCategoriesIndex from "views/settings/portalSettings/blogsCategories/Index";
// import AddBlogCategory from "views/settings/portalSettings/blogsCategories/AddBlogCategory";
// import EditBlogCategory from "views/settings/portalSettings/blogsCategories/EditBlogCategory";

// import EditFontWeight from "views/settings/portalSettings/fontWeights/Edit";
// import AddFontWeight from "views/fontWeights/Add";
// import FontWeightsIndex from "views/fontWeights/Index";

const App = () => {
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");

  //selectors
  const { isLoggedIn } = useSelector((state) => state?.authentication);

  useEffect(() => {
    if (token) dispatch(getCurrentUser());
  }, [token, dispatch]);

  const RenderApp = () => {
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <BrowserRouter>
          <Routes>
            <Route element={<Layout />}>
              <Route
                path="/"
                element={
                  <AuthRoute>
                    <Dashboard />
                  </AuthRoute>
                }
              />
              {/* <Route
                path="/dashboard"
                element={
                  <AuthRoute>
                    <Dashboard />
                  </AuthRoute>
                }
              /> */}

              <Route
                path="/portal/portalTechnicalSupport"
                element={
                  <AuthRoute route="/portal/portalTechnicalSupport">
                    <PortalTechnicalSupport />
                  </AuthRoute>
                }
              />
              <Route
                path="/portalTechnicalSupport/add"
                element={<AddTechnical />}
              />
              <Route
                path="/portal/portalTechnicalSupport/:id"
                element={<ShowTechnical />}
              />
              <Route
                path="/portal/portalTechnicalSupport/heading"
                element={<TechnicalSupportHeading />}
              />

              <Route
                path="/users"
                element={
                  <AuthRoute route="/users">
                    <UsersIndex />
                  </AuthRoute>
                }
              />
              <Route path="/users/adduser" element={<AddUser />} />

              <Route path="/users/:id" element={<EditUser />} />

              <Route
                path="/portal/packages"
                element={
                  <AuthRoute route="/portal/packages">
                    <PackagesIndex />
                  </AuthRoute>
                }
              />

              <Route
                path="/portal/packages/addpackage"
                element={<AddPackage />}
              />

              <Route path="/portal/packages/:id" element={<EditPackage />} />

              <Route
                path="/categories"
                element={
                  <AuthRoute route="/categories">
                    <CategoriesIndex />
                  </AuthRoute>
                }
              />

              <Route path="/categories/addcategory" element={<AddCategory />} />

              <Route path="/categories/:id" element={<EditCategory />} />

              <Route
                path="/portal/features"
                element={
                  <AuthRoute route="/portal/features">
                    <FeaturesIndex />
                  </AuthRoute>
                }
              />

              <Route
                path="/portal/features/addfeature"
                element={<AddFeature />}
              />

              <Route path="/portal/features/:id" element={<EditFeature />} />

              <Route path="/portal/seoData" element={<SeoData />} />

              <Route
                path="/permissions"
                element={
                  <AuthRoute route="/permissions">
                    <Roles />
                  </AuthRoute>
                }
              />
              <Route
                path="/permissions/roles"
                element={
                  <AuthRoute route="/permissions">
                    <Roles />
                  </AuthRoute>
                }
              />

              <Route
                path="/permissions/addPermission"
                element={<AddPermission />}
              />

              <Route path="/permissions/roles/add-role" element={<AddRole />} />
              <Route path="/permissions/roles/:id" element={<EditRole />} />

              <Route
                path="/permissions/users"
                element={
                  <AuthRoute route="/permissions/users">
                    <Users />
                  </AuthRoute>
                }
              />

              <Route path="/permissions/addGroup" element={<AddGroup />} />
              <Route path="/permissions/groups/:id" element={<EditGroup />} />

              <Route path="/permissions/:id" element={<EditPermission />} />

              <Route
                path="/paymentmethods"
                element={
                  <AuthRoute route="/paymentmethods">
                    <PaymentMethodsIndex />
                  </AuthRoute>
                }
              />

              <Route
                path="/paymentmethods/addpaymentmethod"
                element={
                  <AuthRoute route="/paymentmethods/addpaymentmethod">
                    <AddPaymentMethod />
                  </AuthRoute>
                }
              />

              <Route
                path="/paymentmethods/:id"
                element={
                  <AuthRoute route="/paymentmethods/:id">
                    <EditPaymentMethod />
                  </AuthRoute>
                }
              />
              {/* ---------------------------------- themes ------------------------------------- */}
              <Route
                path="/themes"
                element={
                  <AuthRoute route="/themes">
                    <ThemesIndex />
                  </AuthRoute>
                }
              />

              <Route
                path="/themes/addtheme"
                element={
                  <AuthRoute route="/themes/addtheme">
                    <AddTheme />
                  </AuthRoute>
                }
              />

              <Route
                path="/themes/:id"
                element={
                  <AuthRoute route="/themes/:id">
                    <EditTheme />
                  </AuthRoute>
                }
              />

              {/* ---------------------------------- themes ------------------------------------- */}
              <Route
                path="/themes/components"
                element={
                  <AuthRoute route="/themes/components">
                    <ThemesComponentsIndex />
                  </AuthRoute>
                }
              ></Route>
              <Route
                path="/themes/components/add"
                element={
                  <AuthRoute route="/themes/components/add">
                    <AddThemesComponents />
                  </AuthRoute>
                }
              />
              <Route
                path="/themes/components/:id"
                element={
                  <AuthRoute route="/themes/components/:id">
                    <EditThemesComponents />
                  </AuthRoute>
                }
              />

              {/* ----------------- */}
              <Route
                path={"/themes/componentTypes"}
                element={
                  <AuthRoute route={"/themes/componentTypes"}>
                    <ThemesComponentsTypesIndex />
                  </AuthRoute>
                }
              />

              <Route
                path={`/themes/componentTypes/add`}
                element={<AddThemesComponentsType />}
              />

              <Route
                path={`/themes/componentTypes/:id`}
                element={<EditThemesComponentsType />}
              />
              {/* ----------------- */}
              <Route
                path={`/themes/pages`}
                element={
                  <AuthRoute route={`/themes/pages`}>
                    <ThemesPagesIndex />
                  </AuthRoute>
                }
              />

              <Route path={`/themes/pages/add`} element={<AddThemesPages />} />

              <Route path={`/themes/pages/:id`} element={<EditThemesPages />} />
              {/* ----------------- */}
              <Route
                path={`/themes/templates`}
                element={
                  <AuthRoute route={`/themes/templates`}>
                    <ThemesTemplatesIndex />
                  </AuthRoute>
                }
              />

              <Route
                path={`/themes/templates/add`}
                element={<AddThemesTemplates />}
              />

              <Route
                path={`/themes/templates/:id`}
                element={<EditThemesTemplates />}
              />
              {/* ------ */}
              <Route
                path={`/themes/template/:templateId/components`}
                element={
                  <AuthRoute route={`/themes/template/:templateId/components`}>
                    <ThemesTemplateComponentsIndex />
                  </AuthRoute>
                }
              />

              <Route
                path={`/themes/template/:templateId/component/add`}
                element={<AddThemesTemplateComponents />}
              />

              <Route
                path={`/themes/template/:templateId/component/:id`}
                element={<EditThemesTemplateComponents />}
              />

              {/* ---------------------------------- themes ------------------------------------- */}

              <Route
                path="/countries"
                element={
                  <AuthRoute route="/countries">
                    <CountriesIndex />
                  </AuthRoute>
                }
              />

              <Route path="/countries/addcountry" element={<AddCountry />} />

              <Route path="/countries/:id" element={<EditCountry />} />

              <Route
                path="/cities"
                element={
                  <AuthRoute route="/cities">
                    <CitiesIndex />
                  </AuthRoute>
                }
              />

              <Route path="/cities/addcity" element={<AddCity />} />

              <Route path="/cities/:id" element={<EditCity />} />

              <Route
                path="/areas"
                element={
                  <AuthRoute route="/areas">
                    <AreasIndex />
                  </AuthRoute>
                }
              />

              <Route path="/areas/addarea" element={<AddArea />} />

              <Route path="/areas/:id" element={<EditArea />} />

              <Route
                path="/languages"
                element={
                  <AuthRoute route="/languages">
                    <LanguagesInex />
                  </AuthRoute>
                }
              />

              <Route path="/languages/add" element={<AddLanguage />} />

              <Route path="/languages/:id" element={<EditLanguage />} />

              <Route
                path="/fonts"
                element={
                  <AuthRoute route="/fonts">
                    <FontsIndex />
                  </AuthRoute>
                }
              />

              <Route path="/fonts/add" element={<AddFont />} />

              <Route path="/fonts/:id" element={<EditFont />} />

              {/* <Route
                path="/font-weights"
                element={
                  <AuthRoute>
               path=ont-weights"
                     <FontWeightsIndex />
                  </AuthRoute>
                }
              />

              <Route
                path="/font-weights/add"
                element={
                  <AuthRoute path="/font-weights/add">
                    <AddFontWeight />
                  </AuthRoute>
                }
              />

              <Route
                path="/font-weights/:id"
                element={
                  <AuthRoute path="/font-weights/:id">
                    <EditFontWeight />
                  </AuthRoute>
                }
              /> */}

              <Route
                path="/producttypes"
                element={
                  <AuthRoute route="/producttypes">
                    <ProductTypesInex />
                  </AuthRoute>
                }
              />

              <Route
                path="/producttypes/addproducttype"
                element={<AddProductType />}
              />

              <Route path="/producttypes/:id" element={<EditProductType />} />

              <Route
                path="/units"
                element={
                  <AuthRoute route="/units">
                    <UnitsInex />
                  </AuthRoute>
                }
              />

              <Route path="/units/add" element={<AddUnit />} />

              <Route path="/units/:id" element={<EditUnit />} />

              <Route
                path="/banks"
                element={
                  <AuthRoute route="/banks">
                    <BanksInex />
                  </AuthRoute>
                }
              />

              <Route path="/banks/add" element={<AddBank />} />

              <Route path="/banks/:id" element={<EditBank />} />

              <Route
                path="/currencies"
                element={
                  <AuthRoute route="/currencies">
                    <CurrenciesInex />
                  </AuthRoute>
                }
              />

              <Route path="/currencies/add" element={<AddCurrency />} />

              <Route path="/currencies/:id" element={<EditCurrency />} />

              <Route
                exact
                path="/portal"
                element={
                  <AuthRoute route="/portal">
                    <Settings />
                  </AuthRoute>
                }
              />

              <Route
                exact
                path="/portal/settings"
                element={
                  <AuthRoute route="/portal/settings">
                    <Settings />
                  </AuthRoute>
                }
              />

              {/* -----------PORTAL---------- */}
              <Route
                path="/portal/helpCategories"
                element={
                  <AuthRoute route="/portal/helpCategories">
                    <PortalHelpCategories />
                  </AuthRoute>
                }
              />
              <Route
                path="/portal/helpCategories/add"
                element={<AddHelpCategory />}
              />
              <Route
                path="/portal/helpCategories/:id"
                element={<EditHelpCategory />}
              />

              {/* --------------------- */}
              <Route
                path="/portal/helpBlogs"
                element={
                  <AuthRoute route="/portal/helpBlogs">
                    <HelpBlogs />
                  </AuthRoute>
                }
              />
              <Route path="/portal/helpBlogs/add" element={<AddHelpBlog />} />
              <Route path="/portal/helpBlogs/:id" element={<EditHelpBlog />} />
              <Route
                path="/portal/helpBlogs/show/:id"
                element={<ShowHelpBlog />}
              />

              <Route
                path="/portal/subscribers"
                element={
                  <AuthRoute route="/portal/subscribers">
                    <Subscribers />
                  </AuthRoute>
                }
              />
              {/* --------------------- */}
              <Route
                path="/portal/faqs"
                element={
                  <AuthRoute route="/portal/faqs">
                    <Faqs />
                  </AuthRoute>
                }
              />
              <Route path="/portal/faqs/add" element={<AddFaq />} />
              <Route path="/portal/faqs/:id" element={<EditFaq />} />
              <Route path="/portal/faqs/heading" element={<FaqsHeading />} />
              <Route
                path="/portal/reservations"
                element={
                  <AuthRoute route="/portal/reservations">
                    <PortalReservations />
                  </AuthRoute>
                }
              />

              <Route
                path="/portal/reservations/:id"
                element={<ShowReservation />}
              />

              {/* --------------------- */}
              <Route
                path="/portal/blogs"
                element={
                  <AuthRoute route="/portal/blogs">
                    <BlogsIndex />
                  </AuthRoute>
                }
              />

              <Route path="/portal/blogs/addblog" element={<AddBlog />} />

              <Route path="/portal/blogs/:id" element={<EditBlog />} />

              <Route path="/portal/blogs/show/:id" element={<ShowBlog />} />

              <Route path="/portal/blogs/heading" element={<Heading />} />

              <Route
                path="/portal/blogs/categories"
                element={
                  <AuthRoute route="/portal/blogs/categories">
                    <BlogCategoriesIndex />
                  </AuthRoute>
                }
              />

              <Route
                path="/portal/blogs/categories/addCategory"
                element={<AddBlogCategory />}
              />

              <Route
                path="/portal/blogs/categories/:id"
                element={<EditBlogCategory />}
              />

              <Route
                path="/portal/sections"
                element={
                  <AuthRoute route="/portal/sections">
                    <PortalSections />
                  </AuthRoute>
                }
              />

              <Route path="/portal/sections/add" element={<AddSection />} />
              <Route path="/portal/sections/:id" element={<EditSection />} />
              {/* <Route
                path="/settings/infoSettings"
                element={
                  <AuthRoute>
                    <InfoSettings />
                  </AuthRoute>
                }
              /> */}

              <Route
                path="/seoRedirects/"
                element={
                  <AuthRoute>
                    <SEORedirects />
                  </AuthRoute>
                }
              />
              <Route
                path="/seoRedirects/addSeoRedirects"
                element={
                  <AuthRoute>
                    <AddSeoRedirect />
                  </AuthRoute>
                }
              />
              <Route
                path="/seoRedirects/editSeoRedirects/:id"
                element={
                  <AuthRoute>
                    <EditSeoRedirect />
                  </AuthRoute>
                }
              />

              <Route path="*" element={<NotFound />} />
            </Route>

            <Route
              path="/logout"
              element={
                <AuthRoute>
                  <Logout />
                </AuthRoute>
              }
            />
            <Route
              path="/login"
              element={
                <GuestRoute>
                  <Login />
                </GuestRoute>
              }
            />
            <Route
              path="/builder"
              element={
                <AuthRoute>
                  <BuilderPage />
                </AuthRoute>
              }
            />
          </Routes>
        </BrowserRouter>
      </Suspense>
    );
  };

  if (!token) return RenderApp();
  if (isLoggedIn !== null) return RenderApp();
  return <Loader />;
};

export default App;
