import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";

import { editFont, getFonts, getLanguages, getSingleFont } from "store/actions";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Loader from "../../components/shared/Loader";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { getId } from "helpers/functions";
import { FormattedMessage, useIntl } from "react-intl";

const EditFont = () => {
  const { fonts } = useSelector((state) => state.fonts);

  const { languages } = useSelector((state) => state.languages);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const deviceType = searchParams.get("deviceType");
  const intl = useIntl();
  const { singleFont, loading } = useSelector((state) => state?.fonts);
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const fontsProviders = [...new Set(fonts?.map((f) => f?.provider))]?.filter(
    (e) => e?.length > 0
  );

  useEffect(() => {
    dispatch(getLanguages());
    dispatch(getSingleFont(id));
    dispatch(getFonts({ deviceType }));
  }, [dispatch, id, deviceType]);

  useEffect(() => {
    reset({
      ...singleFont,
      // name: singleFont?.name,
      // dir: singleFont?.dir,
    });
  }, [reset, singleFont]);

  const onSubmit = (data) => {
    dispatch(editFont({ id, data, navigate, deviceType }));
  };

  if (loading) return <Loader />;
  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>
              {" "}
              <FormattedMessage id="EditFontDetails" />
            </h4>
            {/* <h4>تعديل بيانات الخط</h4> */}
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={8} md={12}>
                <Row>
                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id={"fontName"} />
                      </h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder={intl.formatMessage({ id: "fontName" })}
                          {...register("name", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.name?.type === "required" && (
                          <FormattedMessage id="PleaseEnterTheFontName" />
                        )}
                      </p>
                    </div>
                  </Col>

                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="FontProvider" />
                      </h5>
                      <div>
                        <Controller
                          control={control}
                          name="provider"
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <CreatableSelect
                              isRtl={true}
                              className="basic-single"
                              classNamePrefix="select"
                              options={fontsProviders?.map((lang) => ({
                                label: lang,
                                value: lang,
                              }))}
                              value={{ value: value, label: value }}
                              placeholder={intl.formatMessage({
                                id: "Choose the font provider",
                              })}
                              onChange={({ value }) => {
                                onChange(value);
                              }}
                            />
                          )}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.provider?.type === "required" && (
                          <FormattedMessage id="PleaseSelectAtLeastOnelanguage" />
                        )}
                      </p>
                    </div>
                  </Col>

                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="FontLanguages" />
                      </h5>
                      <div>
                        <Controller
                          control={control}
                          name="language"
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <Select
                              isRtl={true}
                              className="basic-single"
                              classNamePrefix="select"
                              options={languages?.map((lang) => ({
                                label: lang?.name,
                                value: getId(lang),
                              }))}
                              value={languages
                                ?.filter((e) => value?.includes(getId(e)))
                                ?.map((lang) => ({
                                  label: lang?.name,
                                  value: getId(lang),
                                }))}
                              placeholder={intl.formatMessage({
                                id: "Choose the supported languages",
                              })}
                              isMulti={true}
                              onChange={(ops) => {
                                onChange(ops?.map((e) => e?.value));
                              }}
                            />
                          )}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.languages?.type === "required" && (
                          <formatMessage id="PleaseSelectAtLeastOnelanguage" />
                        )}
                      </p>
                    </div>
                  </Col>

                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="FontSlug" />
                      </h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder={intl.formatMessage({ id: "FontSlug" })}
                          {...register("slug", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.slug?.type === "required" && (
                          <FormattedMessage id="PleaseEnterTheFontSlug" />
                        )}
                      </p>
                    </div>
                  </Col>

                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        <FormattedMessage id="SaveChanges" />
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditFont;
