import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";

import { editThemesPages, getSingleThemesPage } from "store/actions";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Loader from "components/shared/Loader";
import MultiUpload from "components/shared/MultiUpload";
import { getId } from "helpers/functions";
import { FormattedMessage, useIntl } from "react-intl";
const EditTheme = () => {
  const { formatMessage } = useIntl();
  const [selectedImage, setSelectedImage] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const deviceType = searchParams.get("deviceType");

  const { singlePage, loading } = useSelector((state) => state?.pages);

  const { register, handleSubmit, reset ,formState:{errors} } = useForm();

  useEffect(() => {
    dispatch(getSingleThemesPage(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reset({
      name: singlePage?.name,
      category: getId(singlePage?.category),
      description: singlePage?.description,
      tags: singlePage?.tags,
      price: singlePage?.price,
      url: singlePage?.url,
    });
    if (singlePage?.icon?.length > 0) setSelectedImage([singlePage?.icon]);
  }, [singlePage, reset]);

  const onSubmit = (data) => {
    data.icon = selectedImage?.[0];

    // if (selectedImage && selectedImage.path) data["image"] = selectedImage.path;
    // if (data.icon?.length > 0)
    //   data.icon = "uploads/" + data.icon.split("/").pop();

    dispatch(editThemesPages({ id, data, navigate, deviceType }));
  };

  if (loading) return <Loader />;
  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>
              <FormattedMessage id="EditPageData" />
            </h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={10} md={12}>
                <Row>
                  <Col xl={10} md={12}>
                    <Row>
                      <Col lg={12} className="multiUploadWrapper">
                        <div className="form-group required">
                          <h5>{<FormattedMessage id="Page icon" />}</h5>
                          <MultiUpload
                            breakPoints={{ xs: 6, md: 3, lg: 2 }}
                            images={selectedImage}
                            setImages={setSelectedImage}
                            single={true}
                            name="الصفحة"
                          />
                        </div>
                      </Col>
                      <Col lg={6} xs={12}>
                        <div className="form-group required">
                          <h5>
                            <FormattedMessage id="Page name in Arabic" />
                          </h5>
                          <div>
                            <input
                              type="text"
                              className="form-control form-outline"
                              placeholder={formatMessage({
                                id: "Page name in Arabic",
                              })}
                              {...register("name[ar]", { required: true })}
                            />
                          </div>
                          <p className="error-hint">
                        {errors?.name?.["ar"]?.type === "required" &&
                          <FormattedMessage id="PleaseEnterThePageNameInArabic"/>}
                      </p>
                        </div>
                      </Col>
                      <Col lg={6} xs={12}>
                        <div className="form-group required">
                          <h5>
                            <FormattedMessage id="Page name in English" />
                          </h5>
                          <div>
                            <input
                              type="text"
                              className="form-control form-outline"
                              placeholder={formatMessage({
                                id: "Page name in English",
                              })}
                              {...register("name[en]", { required: true })}
                            />
                          </div>
                          <p className="error-hint">
                        {errors?.name?.["en"]?.type === "required" &&
                         <FormattedMessage id="pleaseEnterThePageNameInEnglish"/>}
                      </p>
                        </div>
                      </Col>
                      <Col lg={6} xs={12}>
                        <div className="form-group required">
                          <h5>
                            <FormattedMessage id="Page url" />
                          </h5>
                          <div>
                            <input
                              type="text"
                              className="form-control form-outline"
                              placeholder={formatMessage({ id: "Page url" })}
                              {...register("url", { required: true })}
                              style={{ direction: "ltr" }}
                            />
                          </div>
                          <p className="error-hint">
                        {errors?.url?.type === "required" &&
                         <FormattedMessage id="pleaseEnterThePageLinkCorrectly"/>}
                      </p>
                        </div>
                      </Col>
                    </Row>
                    <Col lg={4} xs={12}>
                      <div className="form-group">
                        <button type="submit" className="btn btn-blue">
                          <FormattedMessage id="Save Edits"/>
                        </button>
                      </div>
                    </Col>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditTheme;
