import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { Col, Row, Modal } from "react-bootstrap";

import {
  editThemesComponent,
  getSingleThemesComponent,
  getThemesComponentsTypes,
} from "store/actions";

import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Select from "react-select";
import { useIntl } from "react-intl";

import Loader from "components/shared/Loader";
import MultiUpload from "components/shared/MultiUpload";
import { getId } from "helpers/functions";
import Builder from "components/Layout/Builder";

const EditThemesComponents = () => {
  const [selectedImage, setSelectedImage] = useState([]);
  const [selectedPreviewImage, setSelectedPreviewImage] = useState([]);
  const [componentData, setComponentData] = useState([]);
  const [searchParams] = useSearchParams();
  const deviceType = searchParams.get("deviceType");

  const { locale } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { singleComponent, loading } = useSelector(
    (state) => state?.components
  );

  const { componentsTypes } = useSelector((state) => state.componentsTypes);

  const { register, handleSubmit, control, reset } = useForm();

  useEffect(() => {
    dispatch(getThemesComponentsTypes(deviceType));
    if (id) {
      dispatch(getSingleThemesComponent(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  useEffect(() => {
    if (id) {
      setComponentData(singleComponent.data || []);
    }
  }, [singleComponent.data, id]);
  useEffect(() => {
    if (id) {
      reset({
        componentType: getId(singleComponent?.componentType),
        name: singleComponent?.name,
      });
      setSelectedImage([singleComponent?.preview]);
      setSelectedPreviewImage([singleComponent?.skeletonPreview]);
    } else {
      reset();
      setSelectedImage([]);
      setSelectedPreviewImage([]);
    }
  }, [singleComponent, reset, id]);

  const onSubmit = (data) => {
    data.preview = selectedImage?.[0];
    data.skeletonPreview = selectedPreviewImage?.[0];

    // if (data.preview?.length > 0)
    //   data.preview = "uploads/" + data.preview.split("/").pop();
    // if (data.skeletonPreview?.length > 0)
    //   data.skeletonPreview = "uploads/" + data.skeletonPreview.split("/").pop();

    data.data = componentData;
    // if (id) {
    dispatch(editThemesComponent({ id, data, navigate, deviceType }));
    // } else {
    //   dispatch(addThemesComponent({ data, navigate }));
    // }
  };

  const renderListComponentsTypes = () => {
    return componentsTypes?.map((componentType) => ({
      label: componentType.name?.[locale],
      value: componentType?.name?.[locale],
      id: getId(componentType),
    }));
  };

  // if (loading) return <Loader />;
  return (
    <Modal
      dialogClassName="productDataModal form-group"
      show={true}
      onHide={() => navigate(-1)}
      size="xl"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <h5 className="mb-0">
            تعديل بيانات العنصر
            </h5>
        </Modal.Header>
        {loading ? (
          <Loader />
        ) : (
          <Modal.Body>
            <Col md={12} className="m-auto">
              <Row>
                <Col xs={6} className="multiUploadWrapper">
                  <div className="form-group required">
                    <h5>
                      صورة العنصر
                      
                    </h5>
                    <MultiUpload
                      breakPoints={{ xs: 6, md: 4, lg: 4 }}
                      images={selectedImage}
                      setImages={setSelectedImage}
                      single={true}
                      name=
                      "ارفع صورة (jpeg / png / jpg)"
                    />
                  </div>
                </Col>
                <Col lg={6} className="multiUploadWrapper">
                  <div className="form-group required">
                    <h5>
                      معاينة العنصر
                      </h5>
                    <MultiUpload
                      breakPoints={{ xs: 6, md: 4, lg: 4 }}
                      images={selectedPreviewImage}
                      setImages={setSelectedPreviewImage}
                      single={true}
                      name="ارفع صورة (jpeg / png / jpg)"
                    />
                  </div>
                </Col>

                <Col lg={4} xs={12}>
                  <div className="form-group required">
                    <h5>
                      اسم العنصر بالعربية

                    </h5>
                    <div>
                      <input
                        type="text"
                        className="form-control form-outline"
                        placeholder="اسم العنصر بالعربية"
                        {...register("name[ar]", { required: true })}
                      />
                    </div>
                  </div>
                </Col>
                <Col lg={4} xs={12}>
                  <div className="form-group required">
                    <h5>
                      اسم العنصر بالانجليزية
                      </h5>
                    <div>
                      <input
                        type="text"
                        className="form-control form-outline"
                        placeholder="اسم العنصر بالانجليزية"
                        {...register("name[en]", { required: true })}
                      />
                    </div>
                  </div>
                </Col>

                <Col lg={4} xs={12}>
                  <div className="form-group required">
                    <h5>
                      نوع العنصر

                    </h5>
                    <div>
                      <Controller
                        control={control}
                        name="componentType"
                        render={({ field: { onChange, value } }) => (
                          <>
                            <Select
                              isRtl={true}
                              // menuIsOpen={true}
                              isSearchable={false}
                              className="basic-single"
                              classNamePrefix="select"
                              options={renderListComponentsTypes()}
                              placeholder="اختار التصنيف"
                              isMulti={false}
                              value={renderListComponentsTypes()?.find(
                                (t) => t.id === value || t.id === getId(value)
                              )}
                              onChange={(value) => {
                                onChange(value.id);
                              }}
                            />
                          </>
                        )}
                      />
                    </div>
                  </div>
                </Col>
                <Col xs={12}>
                  <div className="form-group builderForm">
                    <h5>
                      بيانات العنصر

                    </h5>
                    <div>
                      <Builder
                        componentData={componentData}
                        setComponentData={setComponentData}
                      />
                    </div>
                  </div>
                </Col>

                {/* <Col lg={4} xs={12}>
                      <div className="form-group">
                       
                      </div>
                    </Col> */}
              </Row>
            </Col>
          </Modal.Body>
        )}
        <Modal.Footer>
          <div className="col-3">
            <button type="submit" className="btn btn-blue">
              حفظ التعديلات
            </button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default EditThemesComponents;
