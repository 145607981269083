import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { addUser } from "store/actions";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import { FormattedMessage, useIntl } from "react-intl";

const AddUser = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [usernameOptions, setUsernameOptions] = useState([]);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue,
  } = useForm();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "addresses",
  });
  const intl = useIntl();
  const onSubmit = (data) => {
    console.log(errors?.username?.type , 'add user');
    data.password = data.new_password;
    // data.username = data.new_username;
    data.name = data.new_name;

    console.log("data", data);
    dispatch(addUser({ data, navigate }));
  };
  const newName = watch("new_name");

  useEffect(() => {
    if (newName) {
      const options = generateUsernameOptions(newName);
      setUsernameOptions(options);
      // setValue("userName", options[0]);
    } else {
      setUsernameOptions([]);
      // setValue("userName", "");
    }
  }, [newName, setValue]);

  const generateUsernameOptions = (name) => {
    const randomNum1 = Math.floor(Math.random() * 900) + 100;
    const randomNum2 = Math.floor(Math.random() * 900) + 100;
    const randomNum3 = Math.floor(Math.random() * 900) + 100;

    return [
      `${name}${randomNum1}`,
      `${name}${randomNum2}`,
      `${name}${randomNum3}`,
    ];
  };
  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>
              <FormattedMessage id="Add New Client" />
            </h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <Col xl={8} md={12}>
                <Row>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="ClientName" />{" "}
                      </h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder={intl.formatMessage({
                            id: "Enter ClientName",
                          })}
                          {...register("new_name", { required: true })}
                          autoComplete="user-name"
                        />
                      </div>
                      <p className="error-hint">
                        {/* {errors?.name?.type === "required" && */}
                        {errors?.new_name?.type === "required" && (
                          <FormattedMessage id="pleaseEnterTheClient'sName" />
                        )}
                      </p>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="UserName" />{" "}
                      </h5>
                      <div>
                        <select
                          className="form-control form-outline"
                          placeholder={intl.formatMessage({
                            id: "selectAUsername",
                          })}
                          {...register("username", { required: true })}
                          onChange={(e) => setValue("username", e.target.value)}
                        >
                          <option value="" disabled selected>
                            <FormattedMessage id="selectAUsername" />
                          </option>
                          {usernameOptions?.map((username, index) => (
                            <option key={index} value={username}>
                              {username}
                            </option>
                          ))}
                        </select>
                      </div>
                      <p className="error-hint">
                        {errors?.userName?.type === "required" && (
                          <FormattedMessage id="pleaseEnterTheUsername" />
                        )}
                      </p>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="mobileNumber" />{" "}
                      </h5>
                      <div>
                        <Controller
                          name="phone"
                          control={control}
                          rules={{
                            validate: (value) =>
                              value?.length > 13 || value?.length < 9
                                ? "enterCorrectPhone"
                                : true,
                          }}
                          render={({ field: { value, onChange } }) => (
                            <div dir="ltr">
                              <PhoneInput
                                country="sa"
                                placeholder={intl.formatMessage({
                                  id: "enterTheMobileNumber",
                                })}
                                value={value}
                                onChange={onChange}
                              />
                            </div>
                          )}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.phone?.type === "required" ? (
                          <FormattedMessage id="pleaseEnterTheMobileNumber" />
                        ) : (
                          <FormattedMessage
                            id={errors?.phone?.message || " "}
                          />
                        )}
                      </p>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="email" />{" "}
                      </h5>
                      <div>
                        <input
                          type="email"
                          className="form-control form-outline"
                          placeholder={intl.formatMessage({
                            id: "enterTheEmailAddress",
                          })}
                          {...register("email", { required: true })}
                          autoComplete="user-email"
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.email?.type === "required" &&
                          (
                            <FormattedMessage id="pleaseEnterTheEmailAddress" />
                          )}
                      </p>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="Password" />
                      </h5>
                      <div>
                        <input
                          type="password"
                          className="form-control form-outline"
                          placeholder={intl.formatMessage({ id: "EnterThepassword" })}
                          {...register("new_password", { required: true })}
                          autoComplete="user-password"
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.new_password?.type === "required" &&
                           (
                            <FormattedMessage id="PleaseEnterThePassword" />
                          )}
                      </p>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="form-group branches">
                      <h5>
                        <FormattedMessage id="Titles" />
                      </h5>

                      {fields.map((item, index) => {
                        return (
                          <div key={item.id} className="add-multi-component">
                            <button
                              type="button"
                              onClick={() => remove(index)}
                              className="removeImg"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="12"
                                fill="currentColor"
                                className="bi bi-x-lg"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
                                />
                                <path
                                  fillRule="evenodd"
                                  d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
                                />
                              </svg>
                            </button>
                            <Row>
                              <Col lg={12} xs={12}>
                                <div className="form-group">
                                  <h5>
                                    <FormattedMessage id="detailedTitle" />{" "}
                                  </h5>

                                  <input
                                    type="text"
                                    className="form-control form-outline"
                                    placeholder={intl.formatMessage({
                                      id: "detailedTitle",
                                    })}
                                    {...register(
                                      `addresses.${index}.addressDetail`
                                    )}
                                  />
                                  
                                </div>
                              </Col>
                            </Row>
                          </div>
                        );
                      })}
                      <Col lg={3} xs={12} className="text-left">
                        <button
                          type="button"
                          className="btn btn-green"
                          onClick={() => {
                            append({});
                          }}
                        >
                          <FormattedMessage id="addTitle" />
                        </button>
                      </Col>
                    </div>
                  </Col>
                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        <FormattedMessage id="Add" />
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddUser;
