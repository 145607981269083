import React from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { addLanguage } from "store/actions";
import { useNavigate } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
const AddLanguage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    dispatch(addLanguage({ data, navigate }));
  };

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>
              <FormattedMessage id="addNewLanguage" />
            </h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={10} md={12}>
                <Row>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="languageName" />
                      </h5>{" "}
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder={intl.formatMessage({
                            id: "languageName",
                          })}
                          {...register("name", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.name?.type === "required" && (
                          <FormattedMessage id="enterLanguageName" />
                        )}
                      </p>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="languageCode" />
                      </h5>{" "}
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder={intl.formatMessage({
                            id: "languageCode",
                          })}
                          {...register("locale", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.locale?.type === "required" && (
                          <FormattedMessage id="selectLanguageCode" />
                        )}
                      </p>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="languageDirection" />
                      </h5>{" "}
                      <div>
                        <select
                          className="form-control"
                          name="dir"
                          {...register("dir", { required: true })}
                        >
                          <option value="">
                            <FormattedMessage id="selectLanguageDirection" />
                          </option>
                          <option value="ltr">
                            <FormattedMessage id="leftToRight" />
                          </option>
                          <option value="rtl">
                            <FormattedMessage id="rightToLeft" />
                          </option>
                        </select>
                      </div>
                      <p className="error-hint">
                        {errors?.dir?.type === "required" && (
                          <FormattedMessage id="selectLanguageDirection" />
                        )}
                      </p>
                    </div>
                  </Col>
                  <Row />
                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        <FormattedMessage id="addLanguage" />
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddLanguage;
