import React from "react";
import { FormattedMessage } from "react-intl";

const ErrorPage = () => {
  return (
    <>
      {/* <div className="error-outer">
        <h1>Error 404</h1>
      </div> */}
      <section className="page_404">
        <div className="text-center">
          <div className="four_zero_four_bg">
            <h1 className="text-center ">
              <FormattedMessage id="404" />
            </h1>
          </div>

          <div className="contant_box_404">
            <h3 className="h2">
              <FormattedMessage id="lookLikeYouAreLost" />
            </h3>
            <p>
              <FormattedMessage id="thePageNotAvailable" />
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default ErrorPage;
