import React from "react";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage , useIntl } from "react-intl";

const SeoForm = ({ errors, register, required = true }) => {
  const{formatMessage}=useIntl()
  return (
    <div className="seo-form form-group">
      <hr />
      <h5>
        <FormattedMessage id="SeoInformations"/>(SEO)</h5>
      <Row>
        {/* <Col md={6} xs="12">
          <MultiLangWrapper
            label="عنوان السيو"
            errors={errors?.seo?.title}
            labelClassName="required"
          >
            {({ lang, classes, key }) => (
              <input
                key={key}
                type="text"
                className={`form-control form-outline ${classes}`}
                placeholder="عنوان السيو"
                {...register(`seo.title.${lang}`, { required: required })}
              />
            )}
            <p className="error-hint">
              {errors?.seo?.title && "يرجي ادخال العنوان"}
            </p>
          </MultiLangWrapper>
        </Col> */}

        {/* <Col xs="12">
          <MultiLangWrapper
            label="وصف السيو"
            errors={errors?.seo?.description}
            labelClassName="required"
          >
            {({ lang, classes, key }) => (
              <textarea
                key={key}
                type="text"
                className={`form-control form-outline ${classes}`}
                placeholder="وصف السيو"
                {...register(`seo.description.${lang}`, {
                  required: required,
                })}
              />
            )}
            <p className="error-hint">
              {errors?.seo?.description && "يرجي ادخال الوصف"}
            </p>
          </MultiLangWrapper>
        </Col> */}

        <Col md={12} xs={12}>
          <div className="form-group">
            <h5> <FormattedMessage id="SeoTitle"/></h5>
            <div>
              <input
                type="text"
                className="form-control form-outline"
                placeholder={formatMessage({id:"SeoTitle"})}
                {...register("seo.title")}
              />
            </div>
          </div>
        </Col>

        {/* <Col md={6} xs={12}>
          <div className="form-group">
            <h5>عنوان Canonical</h5>
            <div>
              <input
                type="text"
                className="form-control form-outline"
                placeholder="عنوان Canonical"
                {...register("seo.canonicalTag")}
              />
            </div>
          </div>
        </Col> */}

        <Col xs={12}>
          <div className="form-group">
            <h5>
            <FormattedMessage id="SeoDescribtion"/>
            </h5>
            <div>
              <textarea
                type="text"
                className="form-control form-outline"
                placeholder={formatMessage({id:"SeoDescribtion"})}
                {...register("seo.description")}
              />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SeoForm;
