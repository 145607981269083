/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { IntlProvider } from "react-intl";
import Arabic from "./lang/ar.json";
import English from "./lang/en.json";

const Wrapper = (props) => {
  const message = {
    en: English,
    ar: Arabic,
  };
  
  const [locale, setLocale] = useState("ar");
  // const [messages, setMessages] = useState(Arabic);

  return (
    // selectLanguage
    <Context.Provider value={{ locale,setLocale }}>
      <IntlProvider 
      messages={message[locale]} locale={locale} onError={() => null}>
        {props.children}
      </IntlProvider>
      
    </Context.Provider>
  );
};

export const Context = React.createContext();
export default Wrapper;
