import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import styles from "./home.module.scss";

const TopBlockItem = ({ topcard }) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const { svg, text, to, children } = topcard;
  const { t } = useTranslation();

  const toggleAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  return (
    <Col xxl={4} xl={4} lg={4} md={4} sm={4} xs={6}>
      <div className={styles["home-section"]}>
        <div className="card">
          <div className="contain">
            <button
              onClick={toggleAccordion}
              className={`arrow ${isAccordionOpen ? "active" : ""}`}
              aria-label="Toggle accordion"
            ></button>
          </div>
          <i>{svg}</i>
          <NavLink to={to}>
            <h4>{t(text)}</h4>
          </NavLink>
          {isAccordionOpen && (
            <ul>
              {children?.map((child, index) => (
                <li key={index} className="list">
                  <NavLink to={child.to}>{t(child.text)}</NavLink>
                  {child.children && (
                    <ul>
                      {child.children.map((subChild, subIndex) => (
                        <li key={subIndex} className="sub-list-item">
                          <NavLink to={subChild.to}>{t(subChild.text)}</NavLink>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </Col>
  );
};

export default TopBlockItem;
