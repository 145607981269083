import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { editSection, getSection } from "store/actions";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "components/shared/Loader";
import { FormattedMessage } from "react-intl";
import ImageUpload from "components/shared/ImageUpload";
import { Editor } from "components/shared/Editor";
import SlidersArray from "./SliderArray";

const EditSection = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [sliders, setSliders] = useState([]);
  const { singleSection, loading } = useSelector(
    (state) => state?.portalSections
  );

  const { register, handleSubmit, reset, control } = useForm();

  useEffect(() => {
    dispatch(getSection(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (singleSection?.content?.items) {
      setSliders(
        singleSection?.content?.items?.map((item, index) => ({
          ...item,
          id: index,
        }))
      );

      delete singleSection.content.items;
    }

    reset(singleSection);

    return () => reset({});
  }, [reset, singleSection]);

  const onSubmit = (data) => {
    // if (data.backgroundImage)
    //   data.backgroundImage = "uploads/" + data.backgroundImage.split("/").pop();
    // if (data.content.darkBackgroundImage)
    //   data.content.darkBackgroundImage =
    //     "uploads/" + data.content.darkBackgroundImage.split("/").pop();

    data.content = { ...(data.content || {}) };
    data.content.items = sliders;
    // ?.map((item) => ({
    //   ...item,
    //   image:
    //     // "uploads/" +
    //     item?.image,
    //   //  ?.split("/").pop(),
    // }));

    dispatch(
      editSection({ id, data, navigate: () => navigate("/portal/sections") })
    );
  };

  if (loading) return <Loader />;

  return (
    <div className="acc-form">
      <div className="card">
        <div className="card-head">
          <h4>
            <FormattedMessage id="editSection" />
          </h4>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Col xl={12} md={12}>
              <Row>
                <Col xs={12} className="multiUploadWrapper">
                  <div className="form-group backgroundImage">
                    <h5>
                      <FormattedMessage id="backgroundImage" />
                    </h5>
                    <Controller
                      control={control}
                      name="backgroundImage"
                      render={({ field: { value, onChange } }) => (
                        <ImageUpload
                          selectedImage={value}
                          setSelectedImage={(image) => onChange(image)}
                        />
                      )}
                    />
                  </div>
                </Col>
                {singleSection?.slug === "no-title-2" && (
                  <Col xs={12} className="multiUploadWrapper">
                    <div className="form-group backgroundImage">
                      <h5>
                        <FormattedMessage id="darkBackgroundImage" />
                      </h5>
                      <Controller
                        control={control}
                        name="content.darkBackgroundImage"
                        render={({ field: { value, onChange } }) => (
                          <ImageUpload
                            selectedImage={value}
                            setSelectedImage={(image) => onChange(image)}
                          />
                        )}
                      />
                    </div>
                  </Col>
                )}
                <Col lg={6} xs={12}>
                  <div className="form-group">
                    <h5>
                      <FormattedMessage id="title" /> (
                      <FormattedMessage id="arabic" />)
                    </h5>
                    <div>
                      <input
                        type="text"
                        className="form-control form-outline"
                        {...register("title.ar")}
                      />
                    </div>
                  </div>
                </Col>
                <Col lg={6} xs={12}>
                  <div className="form-group">
                    <h5>
                      <FormattedMessage id="title" /> (
                      <FormattedMessage id="english" />)
                    </h5>
                    <div>
                      <input
                        type="text"
                        className="form-control form-outline"
                        {...register("title.en")}
                      />
                    </div>
                  </div>
                </Col>
                <Col lg={6} xs={12}>
                  <div className="form-group">
                    <h5>
                      <FormattedMessage id="subTitle" /> (
                      <FormattedMessage id="arabic" />)
                    </h5>
                    <div>
                      <input
                        type="text"
                        className="form-control form-outline"
                        {...register("subTitle.ar")}
                      />
                    </div>
                  </div>
                </Col>
                <Col lg={6} xs={12}>
                  <div className="form-group">
                    <h5>
                      <FormattedMessage id="subTitle" /> (
                      <FormattedMessage id="english" />)
                    </h5>
                    <div>
                      <input
                        type="text"
                        className="form-control form-outline"
                        {...register("subTitle.en")}
                      />
                    </div>
                  </div>
                </Col>

                <Col xl={6} xs={12}>
                  <div className="form-group">
                    <h5>
                      <FormattedMessage id="description" /> (
                      <FormattedMessage id="arabic" />)
                    </h5>
                    <div>
                      <Controller
                        control={control}
                        name="subTitle.description.ar"
                        render={({ field: { value, onChange } }) => (
                          <Editor
                            initialValue={value}
                            onChange={(e, editor) => {
                              const data = editor.getContent();
                              onChange(data);
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                </Col>

                <Col xl={6} xs={12}>
                  <div className="form-group">
                    <h5>
                      <FormattedMessage id="description" /> (
                      <FormattedMessage id="english" />)
                    </h5>
                    <div>
                      <Controller
                        control={control}
                        name="subTitle.description.en"
                        render={({ field: { value, onChange } }) => (
                          <Editor
                            initialValue={value}
                            onChange={(e, editor) => {
                              const data = editor.getContent();
                              onChange(data);
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                </Col>
                <SlidersArray
                  {...{ sliders, setSliders, editPageFlag: true }}
                />

                <Col lg={4} xs={12}>
                  <div className="form-group">
                    <button type="submit" className="btn btn-blue">
                      <FormattedMessage id="save" />
                    </button>
                  </div>
                </Col>
              </Row>
            </Col>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditSection;
