import ControlArea from "components/Layout/ControlArea";
import React from "react";
import { useSelector } from "react-redux";
import areasIcon from "assets/svgs/areas.svg";
import Table from "./Table";
import { FormattedMessage } from "react-intl";
const Index = () => {
  const { areas } = useSelector((state) => state.areas);
  return (
    <>
      <div className="stores-wrap">
        <ControlArea
         
          btnTxt={<FormattedMessage id="addNewArea" />}
          cardTxt={<FormattedMessage id="allAreas" />}
          icon={areasIcon}
          url="/areas/addarea"
        />
        <Table data={areas} />
      </div>
    </>
  );
};

export default Index;
