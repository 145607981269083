import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";

import { addPortalHelpCategory, getPortalHelpCategories } from "store/actions";
import { useNavigate } from "react-router-dom";
import server from "api/server";
import imageUpload from "assets/svgs/imageUpload.svg";
import Select from "react-select";
import { FormattedMessage, useIntl } from "react-intl";
import { portalHelpCategoriesTypes } from "helpers/configs";
import Loader from "components/shared/Loader";
import { getId, getPublicImage } from "helpers/functions";

const AddHelpCategory = () => {
  const [selectedImage, setSelectedImage] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { formatMessage, locale } = useIntl();
  const intl = useIntl();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm();

  const { loading, portalHelpCategories } = useSelector(
    (state) => state?.portalHelpCategories
  );

  useEffect(() => {
    if (!watch("type")) return;
    dispatch(getPortalHelpCategories(`?type=${watch("type")}`));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, watch("type")]);

  const imageChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      const { data } = await server().post("/upload", formData);
      setSelectedImage({
        preview: e.target.files[0],
        path: getPublicImage(data.data.files[0]),
      });
    }
  };

  const removeSelectedImage = () => {
    setSelectedImage();
  };

  const onSubmit = (data) => {
    if (selectedImage && selectedImage.path) data["icon"] = selectedImage.path;
    dispatch(addPortalHelpCategory({ data, navigate }));
  };

  if (loading) return <Loader />;
  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>
              <FormattedMessage id="addNewCategory" />
            </h4>{" "}
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={10} md={12}>
                <Row>
                  <Col lg={12}>
                    <div className="form-group required">
                      <h5>
                        {" "}
                        <FormattedMessage id="categoryImage" />{" "}
                      </h5>{" "}
                      <div className="image-uploader image-contain">
                        <div className="img-pat">
                          <img
                            src={imageUpload}
                            width={46}
                            height={46}
                            alt=""
                          />
                        </div>
                        <label>
                          {selectedImage && (
                            <div>
                              <img
                                src={
                                  selectedImage &&
                                  selectedImage.preview &&
                                  URL.createObjectURL(selectedImage.preview)
                                }
                                width={160}
                                height={160}
                                alt=""
                              />
                              <button
                                className="removeImg"
                                onClick={removeSelectedImage}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="12"
                                  height="12"
                                  fill="currentColor"
                                  className="bi bi-x-lg"
                                  viewBox="0 0 16 16"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
                                  />
                                  <path
                                    fillRule="evenodd"
                                    d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
                                  />
                                </svg>
                              </button>
                            </div>
                          )}
                          <input
                            accept="image/*"
                            type="file"
                            onChange={imageChange}
                          />
                        </label>

                        <p className="error-hint">
                          {errors.icon?.type === "required" &&
                            "يرجي اختيار صورة التصنيف"}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>
                        {" "}
                        <FormattedMessage id="categoryNameInArabic" />
                      </h5>{" "}
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder={intl.formatMessage({
                            id: "categoryNameInArabic",
                          })}
                          {...register("title.ar", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.title?.["ar"]?.type === "required" && (
                          <FormattedMessage id="pleaseEnterTheCategoryNameInArabic" />
                        )}
                      </p>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>
                        {" "}
                        <FormattedMessage id="categoryNameInEnglish" />
                      </h5>{" "}
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder={intl.formatMessage({
                            id: "categoryNameInEnglish",
                          })}
                          {...register("title.ar", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.title?.["ar"]?.type === "required" && (
                          <FormattedMessage id="pleaseEnterTheCategoryNameInArabic" />
                        )}
                      </p>
                    </div>
                  </Col>

                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="types" />
                      </h5>
                      <div>
                        <Controller
                          control={control}
                          name="type"
                          render={({ field: { onChange, value } }) => (
                            <Select
                              isRtl={true}
                              // menuIsOpen={true}
                              isSearchable={false}
                              className="basic-single"
                              classNamePrefix="select"
                              options={portalHelpCategoriesTypes?.map(
                                (type) => ({
                                  label: formatMessage({ id: type || " " }),
                                  value: type,
                                })
                              )}
                              value={{
                                label: formatMessage({ id: value || " " }),
                                value: value,
                              }}
                              placeholder={formatMessage({ id: "selectType" })}
                              isMulti={false}
                              onChange={({ value }) => {
                                onChange(value);
                              }}
                            />
                          )}
                        />{" "}
                        <p className="error-hint">
                          {errors?.type === "required" && (
                            <FormattedMessage id="required" />
                          )}
                        </p>
                      </div>
                    </div>
                  </Col>

                  <Col lg={12} xs={12}>
                    <div className="form-group">
                      <h5>
                        <FormattedMessage id="parentCategory" />
                      </h5>
                      <div>
                        <Controller
                          control={control}
                          name="parentCategory"
                          render={({ field: { onChange, value } }) => (
                            <Select
                              key={value}
                              isRtl={true}
                              // menuIsOpen={true}
                              isSearchable={false}
                              className="basic-single"
                              classNamePrefix="select"
                              options={portalHelpCategories?.map((item) => ({
                                label: item?.title?.[locale],
                                value: getId(item),
                              }))}
                              placeholder={formatMessage({
                                id: "selectParentCategory",
                              })}
                              value={portalHelpCategories
                                ?.map((item) => ({
                                  label: item?.title?.[locale],
                                  value: getId(item),
                                }))
                                ?.find((item) => item?.value === value)}
                              isMulti={false}
                              onChange={({ value }) => {
                                onChange(value);
                              }}
                            />
                          )}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.parentCategory === "required" && (
                          <FormattedMessage id="required" />
                        )}
                      </p>
                    </div>
                  </Col>

                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        <FormattedMessage id="addCategory" />
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddHelpCategory;
