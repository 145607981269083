import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { loginUser } from "../store/authentication/actions";
import Loading from "../components/shared/Loader";
import LoginLogo from "../assets/images/tjaaraLogo.svg";
import { FormattedMessage, useIntl } from "react-intl";

function Login() {
  const dispatch = useDispatch();
  const intl =useIntl()
  const { loading, error } = useSelector((state) => state.authentication);
  if (error) toast(error, { toastId: error });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => dispatch(loginUser(data));
  if (loading) return <Loading />;

  return (
    <>
      <div className="login-wrp">
        <div className="login--inner">
          <div className="login-logo">
            <img src={LoginLogo} alt="" />
          </div>
          <div className="login-data">
            <h3>
              <FormattedMessage id="welcomeBack" />
            </h3>
            <p>
              <FormattedMessage id="pleaseLogin" />
            </p>
          </div>
          <div className="login-form">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group">
                <svg
                  width="22"
                  height="16"
                  viewBox="0 0 22 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M21.8228 0.933594L21.0728 0.183594H1.57275L0.822754 0.933594V14.4336L1.57275 15.1836H21.0728L21.8228 14.4336V0.933594ZM20.3228 2.48609V13.6836H2.32275V2.48759L10.8578 9.03359H11.7728L20.3228 2.48609ZM3.77775 1.68359H18.8678L11.3228 7.48709L3.77775 1.68359Z"
                    fill="#e94f38"
                  />
                </svg>

                <input
                  {...register("email")}
                  className="form-control"
                  placeholder={intl.formatMessage({
                    id: "email",
                  })}                />
              </div>
              <div className="form-group">
                <svg
                  width="17"
                  height="21"
                  viewBox="0 0 17 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.322754 10.6836C0.322754 9.58059 1.21975 8.68359 2.32275 8.68359H3.32275V5.68359C3.32275 2.92659 5.56575 0.683594 8.32275 0.683594C11.0798 0.683594 13.3228 2.92659 13.3228 5.68359V8.68359H14.3228C15.4258 8.68359 16.3228 9.58059 16.3228 10.6836V18.6836C16.3228 19.7866 15.4258 20.6836 14.3228 20.6836H2.32275C1.21975 20.6836 0.322754 19.7866 0.322754 18.6836V10.6836ZM11.3228 5.68359C11.3228 4.02959 9.97675 2.68359 8.32275 2.68359C6.66875 2.68359 5.32275 4.02959 5.32275 5.68359V8.68359H11.3228V5.68359Z"
                    fill="#e94f38"
                  />
                </svg>

                <input
                  type="password"
                  {...register("password")}
                  className="form-control"
                  placeholder={intl.formatMessage({
                    id: "password",
                  })}
                />
              </div>
              <div className="form-group">
                <button type="submit" className="btn btn-blue">
                  <FormattedMessage id="login" />
                </button>
              </div>
            </form>
            {errors.exampleRequired && (
              <span>
                <FormattedMessage id="thisFieldIsRequired" />
              </span>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
