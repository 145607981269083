import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap";

import {
  addThemesTemplateComponent,
  getThemeComponents,
  getThemesPages,
} from "store/actions";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Select from "react-select";
import { FormattedMessage, useIntl } from "react-intl";
import Loader from "components/shared/Loader";
const AddTheme = () => {
  const { locale } = useIntl();
  const { templateId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pages } = useSelector((state) => state.pages);
  const { components } = useSelector((state) => state.components);
  const { loading } = useSelector((state) => state.templateComponents);
  const [searchParams] = useSearchParams();
  const deviceType = searchParams.get("deviceType");

  const intl = useIntl();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    dispatch(getThemeComponents({ templateId, deviceType }));
    dispatch(getThemesPages(deviceType));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const onSubmit = (data) => {
    dispatch(
      addThemesTemplateComponent({ templateId, data, navigate, deviceType })
    );
  };
  const renderListPages = () => {
    return pages?.map((category) => ({
      label: category.name?.[locale],
      value: category?.name?.[locale],
      id: category?.id,
    }));
  };
  const renderListComponents = () => {
    console.log("components", components);
    return components?.map((category) => ({
      label: category.name?.[locale],
      value: category?.name?.[locale],
      id: category?.id,
    }));
  };
  if (loading) return <Loader />;

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>
              <FormattedMessage id="AddANewItemToTheTemplate" />{" "}
            </h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={10} md={12}>
                <Row>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="Page" />{" "}
                      </h5>
                      <div>
                        <Controller
                          control={control}
                          name="page"
                          render={({ field: { onChange } }) => (
                            <Select
                              isRtl={true}
                              // menuIsOpen={true}
                              isSearchable={false}
                              className="basic-single"
                              classNamePrefix="select"
                              options={renderListPages()}
                              placeholder={intl.formatMessage({
                                id: "ChooseAPage",
                              })}
                              isMulti={false}
                              onChange={(value) => {
                                onChange(value.id);
                              }}
                            />
                          )}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.page?.type === "required" && (
                          <FormattedMessage id="PleaseEnterThePage" />
                        )}
                      </p>
                    </div>
                  </Col>

                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="Item" />
                      </h5>{" "}
                      <div>
                        <Controller
                          control={control}
                          name="component"
                          render={({ field: { onChange } }) => (
                            <Select
                              isRtl={true}
                              // menuIsOpen={true}
                              isSearchable={false}
                              className="basic-single"
                              classNamePrefix="select"
                              options={renderListComponents()}
                              placeholder={intl.formatMessage({
                                id: "ChooseTheItem",
                              })}
                              isMulti={false}
                              onChange={(value) => {
                                onChange(value.id);
                              }}
                            />
                          )}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.component?.type === "required" && (
                          <FormattedMessage id="PleaseEnterTheItem" />
                        )}
                      </p>
                    </div>
                  </Col>

                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      {/* <h5>رقم الترتيب</h5> */}
                      <h5>
                        <FormattedMessage id="OrderNumber" />
                      </h5>
                      <div>
                        <input
                          type="number"
                          className="form-control form-outline"
                          placeholder={intl.formatMessage({
                            id: "OrderNumber",
                          })}
                          {...register("price", {
                            required: (
                              <FormattedMessage id="PleaseEnterThePackagePrice" />
                            ),
                            validate: (value) =>
                              value > 0 || (
                                <FormattedMessage id="PleaseEnterAPositiveNumber" />
                              ),
                          })}
                          {...register("sort", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.sort?.type === "required" && (
                          <FormattedMessage id="PleaseEnterTheOrderNumber" />
                        )}
                      </p>
                    </div>
                  </Col>
                </Row>
                <Col lg={4} xs={12}>
                  <div className="form-group">
                    <button type="submit" className="btn btn-blue">
                      <FormattedMessage id="AddTemplate" />{" "}
                    </button>
                  </div>
                </Col>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddTheme;
