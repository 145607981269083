import React, { useMemo, useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Col, Dropdown } from "react-bootstrap";

import Filter from "components/shared/Filter";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import {
  deleteThemesTemplateComponent,
  getThemeComponents,
  getThemesPages,
  getThemesTemplateComponents,
} from "store/actions";
import { ReactComponent as DotsIcon } from "assets/svgs/three-dots.svg";
import { ReactComponent as EditIcon } from "assets/svgs/edit.svg";
import { ReactComponent as DeleteIcon } from "assets/svgs/delete.svg";
import Loader from "components/shared/Loader";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getId } from "helpers/functions";
import Swal from "sweetalert2";

const Table = (props) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { locale, formatMessage } = useIntl();
  const [pending, setPending] = useState(true);
  const [rows, setRows] = useState([]);
  const { templateId } = useParams();

  const [searchParams] = useSearchParams();
  const deviceType = searchParams.get("deviceType");

  const { pages } = useSelector((state) => state?.pages);
  const { components } = useSelector((state) => state?.components);
const intl=useIntl()
  useEffect(() => {
    dispatch(getThemesTemplateComponents({ id: templateId, deviceType }));

    // if (!components?.length > 0)
    dispatch(getThemeComponents(deviceType));

    // if (!pages?.length > 0)
    dispatch(getThemesPages(deviceType));

    const timeout = setTimeout(() => {
      setRows(rows);
      setPending(false);
    }, 2000);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, rows, searchParams]);

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const filteredItems = props?.data?.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <Filter
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const handleEditTheme = (id) => {
    navigate(
      `/themes/template/${templateId}/component/${id}?deviceType=${deviceType}`
    );
  };

  const handleDeleteTheme = (id) => {
    Swal.fire({
      title: formatMessage({ id: "deleteThemesTemplateComponent" }),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2a89f3",
      cancelButtonColor: "#d33",
      confirmButtonText: formatMessage({ id: "Yes" }),
      cancelButtonText: formatMessage({ id: "NO" }),
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteThemesTemplateComponent(id));
      }
    });
  };

  const columns = [
    {
      name: intl.formatMessage({ id: "elementName" }),
      selector: (row) => (
        <span>
          {components?.find((e) => getId(e) === row?.component)?.name?.[locale]}
        </span>
      ),
      sortable: true,
    },
    {
      name: intl.formatMessage({ id: "page" }),
      selector: (row) => (
        <span>
          {pages?.find((p) => getId(p) === row?.page)?.name?.[locale]}
        </span>
      ),
      sortable: true,
    },
    {
      name: intl.formatMessage({ id: "order" }),
      selector: (row) => <span>{row?.sort}</span>,
      sortable: true,
    },

    {
      cell: (row) => (
        <div className="global-dropdown">
          <Dropdown>
            <Dropdown.Toggle>
            <DotsIcon />
             
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={(e) => handleEditTheme(getId(row))}>
              <EditIcon className="bi bi-pencil edit" />
              <FormattedMessage id="Edit" />
              </Dropdown.Item>
              <Dropdown.Item onClick={(e) => handleDeleteTheme(getId(row))}>
              <DeleteIcon className="bi bi-trash delete" fill="#fff" />
              <FormattedMessage id="Delete" />
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ),
      allowOverflow: true,
      button: true,
      width: "56px",
    },
  ];

  return (
    <>
      <div className="table-wrap">
        <div className="card">
          <Col xl={6} md={12} xs={12}>
            <div className="card-head">
              <div>
              <h4>
                  <FormattedMessage id="defaultDatatable" />
                </h4>
                <p>
                  <FormattedMessage id="toUseThisWithYourTables,SimplyCallTheConstructorFunction" />
                </p>
              </div>
            </div>
          </Col>
          <div className="card-body">
            <DataTable
              columns={columns}
              data={filteredItems}
              progressPending={pending}
              progressComponent={<Loader />}
              defaultSortField="name"
              //selectableRows
              subHeader
              subHeaderComponent={subHeaderComponent}
              //selectableRowsComponent={BootyCheckbox}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Table;
