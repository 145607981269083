import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import {
  addThemeApi,
  deleteThemeApi,
  editThemeApi,
  getSingleThemeApi,
  getThemesApi,
} from "api/themesTemplatesComponents";

// Login Redux States
import {
  GET_THEMES_TEMPLATE_COMPONENTS,
  ADD_THEMES_TEMPLATE_COMPONENT,
  EDIT_THEMES_TEMPLATE_COMPONENT,
  DELETE_THEMES_TEMPLATE_COMPONENT,
  GET_SINGLE_THEMES_TEMPLATE_COMPONENT,
} from "./actionTypes";
import {
  addThemesTemplateComponentFailure,
  addThemesTemplateComponentSuccess,
  deleteThemesTemplateComponentFailure,
  deleteThemesTemplateComponentSuccess,
  editThemesTemplateComponentFailure,
  editThemesTemplateComponentSuccess,
  getSingleThemesTemplateComponentFailure,
  getSingleThemesTemplateComponentSuccess,
  getThemesTemplateComponentsFailure,
  getThemesTemplateComponentsSuccess,
} from "./actions";

function* getThemes({ payload }) {
  try {
    const { data } = yield call(getThemesApi, payload);
    yield put(getThemesTemplateComponentsSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(
      getThemesTemplateComponentsFailure(error?.response?.data?.errors?.[0].msg)
    );
  }
}

function* getSingleTheme({ payload }) {
  try {
    const { data } = yield call(getSingleThemeApi, payload);
    yield put(getSingleThemesTemplateComponentSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(
      getSingleThemesTemplateComponentFailure(error?.response?.data || error)
    );
  }
}

function* addTheme({ payload }) {
  try {
    const { data } = yield call(addThemeApi, payload);
    yield put(addThemesTemplateComponentSuccess(data));
    console.log("payload",payload);
    console.log(payload?.templateId);
    yield payload.navigate(
      `/themes/template/${payload?.templateId}/components?deviceType=${payload?.deviceType}`
    );
  } catch (error) {
    console.log(error);
    yield put(
      addThemesTemplateComponentFailure(error.response?.data?.message || error)
    );
  }
}

function* editTheme({ payload }) {
  try {
    const { data } = yield call(editThemeApi, payload);
    yield put(editThemesTemplateComponentSuccess(data));
    yield payload.navigate(
      `/themes/template/${payload?.templateId}/components?deviceType=${payload?.deviceType}`
    );
  } catch (error) {
    console.log(error);
    yield put(
      editThemesTemplateComponentFailure(error?.response?.data || error)
    );
  }
}

function* deleteTheme({ payload }) {
  try {
    yield call(deleteThemeApi, payload);
    yield put(deleteThemesTemplateComponentSuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(
      deleteThemesTemplateComponentFailure(error?.response?.data || error)
    );
  }
}

export function* watchGetThemes() {
  yield takeEvery(GET_THEMES_TEMPLATE_COMPONENTS, getThemes);
}

export function* watchGetSingleTheme() {
  yield takeEvery(GET_SINGLE_THEMES_TEMPLATE_COMPONENT, getSingleTheme);
}

export function* watchAddTheme() {
  yield takeEvery(ADD_THEMES_TEMPLATE_COMPONENT, addTheme);
}

export function* watchEditTheme() {
  yield takeEvery(EDIT_THEMES_TEMPLATE_COMPONENT, editTheme);
}

export function* watchDeleteTheme() {
  yield takeEvery(DELETE_THEMES_TEMPLATE_COMPONENT, deleteTheme);
}

function* themesSaga() {
  yield all([fork(watchGetThemes)]);
  yield all([fork(watchGetSingleTheme)]);
  yield all([fork(watchAddTheme)]);
  yield all([fork(watchEditTheme)]);
  yield all([fork(watchDeleteTheme)]);
}

export default themesSaga;
