import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { useForm, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { Editor } from "components/shared/Editor";

import {
  editBlog,
  getBlogCategories,
  getLanguages,
  getSingleBlog,
  getTags,
} from "store/actions";
import { useNavigate, useParams } from "react-router-dom";
import server from "api/server";
import imageUpload from "assets/svgs/imageUpload.svg";
import Loader from "components/shared/Loader";
import { getId, getPublicImage } from "helpers/functions";
import { toast } from "react-toastify";
import SeoForm from "components/shared/SeoForm";

const EditProduct = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { locale, formatMessage } = useIntl();

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const dir = locale === "ar" ? "rtl" : "ltr";

  const [selectedImage, setSelectedImage] = useState({
    preview: null,
    path: null,
  });
  const [description, setDescription] = useState({ ar: "", en: "" });
  const [tags, setTags] = useState([]);

  const { singleBlog, loading } = useSelector((state) => state.blogs);
  const { blogCategories: categories } = useSelector(
    (state) => state.blogCategories
  );
  const { languages } = useSelector((state) => state.languages);

  const renderCategoriesSelectOptions = () => {
    return categories.map((category, index) => {
      return (
        <option value={getId(category)} key={index}>
          {category?.name?.[locale]}
        </option>
      );
    });
  };

  const renderLanguagesSelectOptions = () => {
    return languages.map((language, index) => {
      return (
        <option value={getId(language)} key={index}>
          {language?.name}
        </option>
      );
    });
  };

  const imageChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      const { data } = await server().post("/upload", formData);
      setSelectedImage({
        preview: e.target.files[0],
        path: getPublicImage(data.data.files[0]),
      });
    }
  };

  const removeSelectedImage = () => {
    setSelectedImage();
  };

  useEffect(() => {
    dispatch(getBlogCategories());
    dispatch(getLanguages());
    dispatch(getTags());
    dispatch(getSingleBlog(id));
  }, [dispatch, id]);

  useEffect(() => {
    setTags(singleBlog.tags);
  }, [singleBlog]);

  useEffect(() => {
    setDescription(singleBlog.description);
  }, [singleBlog]);

  useEffect(() => {
    if (singleBlog?.id) {
      reset({
        title: singleBlog?.title,
        language: singleBlog?.language?.id,
        category: getId(singleBlog?.category),
        image: singleBlog?.image,
        tags: singleBlog?.tags,
        description: singleBlog?.description,
        seo: singleBlog?.seo,
      });

      setSelectedImage({ path: singleBlog?.image });
    }
  }, [reset, singleBlog]);

  const onSubmit = (data) => {
    // if (selectedImage && selectedImage.path)
    data["image"] = selectedImage?.path || "";
    if (!data?.image)
      return toast.error(formatMessage({ id: "pleaseSelectImage" }));

    // data.image = "uploads/" + data.image.split("/").pop();

    data.description = description;
    data.tags = tags;
    data.seo.keywords = tags;

    dispatch(editBlog({ id, data, navigate }));
  };

  if (loading) return <Loader />;

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>
            <FormattedMessage id="EditTheBlog" />
            </h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={10} md={12}>
                <Row>
                  <Col lg={12}>
                    <div className="form-group required">
                      <h5>  <FormattedMessage id="BlogImage"/></h5>
                      <div className="image-uploader">
                        <div className="img-pat">
                          <img
                            src={imageUpload}
                            width={46}
                            height={46}
                            alt=""
                          />
                        </div>
                        <label>
                          {selectedImage && (
                            <div>
                              <img
                                src={
                                  selectedImage && selectedImage.preview
                                    ? URL.createObjectURL(selectedImage.preview)
                                    : singleBlog?.image
                                }
                                width={160}
                                height={160}
                                alt=""
                              />
                              <button
                                className="removeImg"
                                onClick={removeSelectedImage}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="12"
                                  height="12"
                                  fill="currentColor"
                                  className="bi bi-x-lg"
                                  viewBox="0 0 16 16"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
                                  />
                                  <path
                                    fillRule="evenodd"
                                    d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
                                  />
                                </svg>
                              </button>
                            </div>
                          )}
                          <input
                            accept="image/*"
                            type="file"
                            onChange={imageChange}
                          />
                        </label>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>  <FormattedMessage id="category"/></h5>
                      <div>
                        <select
                          placeholder={formatMessage({ id: "selectCategory" })}
                          className="form-control form-select"
                          {...register("category", { required: true })}
                        >
                          <option value="">
                            {formatMessage({ id: "selectCategory" })}
                          </option>
                          {renderCategoriesSelectOptions()}
                        </select>
                      </div>
                      <p className="error-hint">
                        {errors.category?.type === "required" && (
                          <formatMessage id="required" />
                        )}
                      </p>
                    </div>
                  </Col>

                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5> <FormattedMessage id="BlogLanguage"/></h5>
                      <div>
                        <select
                          className="form-control form-select"
                          {...register("language", { required: true })}
                        >
                          <option> {formatMessage({ id: "ChooseTheLanguageOfTheArticle" })}</option>
                          {renderLanguagesSelectOptions()}
                        </select>
                      </div>
                    </div>
                  </Col>

                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5> <FormattedMessage id="BlogTitle"/></h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder={formatMessage({id:"BlogTitle"})}
                          {...register("title", { required: true })}
                        />
                      </div>
                    </div>
                  </Col>

                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5> <FormattedMessage id="BlogDescribtiob"/></h5>
                      <div>
                        <Controller
                          control={control}
                          name="description"
                          render={({ field: { value } }) => (
                            <Editor
                              initialValue={singleBlog?.description}
                              onChange={(e, editor) => {
                                const data = editor.getContent();
                                setDescription(data);
                              }}
                              tinymceScriptSrc="https://cdn.tiny.cloud/1/qagffr3pkuv17a8on1afax661irst1hbr4e6tbv888sz91jc/tinymce/5-stable/tinymce.min.js"
                              init={{
                                plugins:
                                  "print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
                                menubar:
                                  "file edit view insert format tools table help",
                                toolbar:
                                  "customInsertButton | undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | charmap emoticons | fullscreen  preview print | insertfile image media link table | ltr rtl | language",
                                toolbar_sticky: true,
                                autosave_ask_before_unload: true,
                                autosave_interval: "30s",
                                autosave_prefix: "{path}{query}-{id}-",
                                autosave_restore_when_empty: false,
                                autosave_retention: "2m",
                                mobile: {
                                  menubar: true,
                                },
                                directionality: `${dir}`,
                                language: `${locale}`,
                                height: 400,
                                image_caption: true,
                                quickbars_selection_toolbar:
                                  "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
                                noneditable_noneditable_class: "mceNonEditable",
                                toolbar_mode: "wrap",
                                contextmenu: "link image imagetools table",
                                content_style: "body {font-size:14px}",
                                setup: function (editor) {
                                  editor.ui.registry.addButton(
                                    "customInsertButton",
                                    {
                                      text: "custom Button",
                                      onAction: function (_) {
                                        editor.insertContent(
                                          `&nbsp;<a href="" class="btn" style="    background: linear-gradient(
																148deg,#1d5cd1,#0ba1d8 84%);
															color: #fff;
															padding: 10px 20px;
															border-radius: 50px;
															display: inline-block;
															cursor: pointer;
															text-decoration: none;">my button!</a>&nbsp;`
                                        );
                                      },
                                    }
                                  );
                                },
                              }}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </Col>

                  {/* <Col lg={12}>
                    <div className="form-group">
                      <h5>اضافة كلمات دلالية</h5>
                      <div>
                        <Controller
                          control={control}
                          name="tags"
                          render={({
                            field: { onChange, value, selected },
                          }) => (
                            <CreatableSelect
                              isMulti
                              isRtl={true}
                              className="basic-single creatable-select"
                              classNamePrefix="select"
                              options={blogTags.map((tag) => ({
                                value: tag,
                                label: tag,
                              }))}
                              defaultValue={
                                renderTags()?.filter((tag) => {
                                  return tag;
                                }) || []
                              }
                              onChange={(selected) => {
                                const newTags = selected.map(
                                  (tag) => tag.label
                                );

                                setTags(newTags);
                              }}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </Col> */}
                  <SeoForm errors={errors} register={register} />

                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        <FormattedMessage id="Save Edits"/>
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProduct;
