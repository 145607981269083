import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { useForm, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { Editor } from "components/shared/Editor";
import {
  addBlog,
  getBlogCategories,
  getLanguages,
  getTags,
} from "store/actions";
import { useNavigate } from "react-router-dom";
import server from "api/server";
import imageUpload from "assets/svgs/imageUpload.svg";
import { toast } from "react-toastify";
import SeoForm from "components/shared/SeoForm";
import { getPublicImage } from "helpers/functions";

const AddBlog = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { locale, formatMessage } = useIntl();

  const [selectedImage, setSelectedImage] = useState();
  const [description, setDescription] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [tags, setTags] = useState([]);

  const { blogCategories: categories } = useSelector(
    (state) => state.blogCategories
  );
  const { languages } = useSelector((state) => state.languages);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const renderCategoriesSelectOptions = () => {
    return categories.map((category, index) => {
      return (
        <option value={category.id} key={index}>
          {category?.name?.[locale]}
        </option>
      );
    });
  };

  const renderLanguagesSelectOptions = () => {
    return languages.map((language, index) => {
      return (
        <option value={language.id} key={index}>
          {language?.name}
        </option>
      );
    });
  };

  const imageChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      const { data } = await server().post("/upload", formData);
      setSelectedImage({
        preview: e.target.files[0],
        path: getPublicImage(data.data.files[0]),
      });
    }
  };

  const removeSelectedImage = () => {
    setSelectedImage();
  };

  useEffect(() => {
    dispatch(getBlogCategories());
    dispatch(getLanguages());
    dispatch(getTags());
  }, [dispatch]);

  const onSubmit = (data) => {
    // if (selectedImage && selectedImage.path)
    data["image"] = selectedImage?.path || "";
    if (!data?.image)
      return toast.error(formatMessage({ id: "pleaseSelectImage" }));
    // data.image = "uploads/" + data?.image?.split("/").pop();
    data.description = description;
    data.tags = tags;
    data.seo.keywords = tags;

    dispatch(addBlog({ data, navigate }));
  };

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>
              <FormattedMessage id="AddNewBlog" />
            </h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={10} md={12}>
                <Row>
                  <Col lg={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="BlogImage"/>
                      </h5>
                      <div className="image-uploader image-contain">
                        <div className="img-pat">
                          <img
                            src={imageUpload}
                            width={46}
                            height={46}
                            alt=""
                          />
                        </div>
                        <label>
                          {selectedImage && (
                            <div>
                              <img
                                src={
                                  selectedImage &&
                                  selectedImage.preview &&
                                  URL.createObjectURL(selectedImage.preview)
                                }
                                width={160}
                                height={160}
                                alt=""
                              />
                              <button
                                className="removeImg"
                                onClick={removeSelectedImage}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="12"
                                  height="12"
                                  fill="currentColor"
                                  className="bi bi-x-lg"
                                  viewBox="0 0 16 16"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
                                  />
                                  <path
                                    fillRule="evenodd"
                                    d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
                                  />
                                </svg>
                              </button>
                            </div>
                          )}
                          <input
                            accept="image/*"
                            type="file"
                            onChange={imageChange}
                          />
                        </label>

                        <p className="error-hint">
                          {errors.mainImage?.type === "required" &&
                           <FormattedMessage id="PleaseEnterTheBlogImage"/>}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="category"/>
                      </h5>
                      <div>
                        <select
                          className="form-control form-select"
                          {...register("category", { required: true })}
                        >
                          <option value="">
                            {formatMessage({ id: "selectCategory" })}
                          </option>
                          {renderCategoriesSelectOptions()}
                        </select>
                      </div>
                      <p className="error-hint">
                        {errors.category?.type === "required" &&
                          <FormattedMessage id="PleaseChoseCategory"/>}
                      </p>
                    </div>
                  </Col>

                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="BlogLanguage"/>
                      </h5>
                      <div>
                        <select
                          className="form-control form-select"
                          {...register("language", { required: true })}
                        >
                          <option value="">
                          {formatMessage({ id: "ChooseTheLanguageOfTheArticle" })}
                          </option>
                          {renderLanguagesSelectOptions()}
                        </select>
                      </div>
                      <p className="error-hint">
                        {errors.language?.type === "required" &&
                         <FormattedMessage id="PleaseEnterTheBlogLanguage"/>}
                      </p>
                    </div>
                  </Col>

                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="BlogTitle"/>
                      </h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder={formatMessage({id:"BlogTitle"})}
                          {...register("title", {
                            required: true,
                          })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors.title?.type === "required" &&
                          <FormattedMessage id="PleaseEnterTheBlogTitle"/>}
                      </p>
                    </div>
                  </Col>
                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="BlogDescribtiob"/>
                      </h5>
                      <div>
                        <Controller
                          control={control}
                          name="description"
                          render={({ field: { value } }) => (
                            <Editor
                              initialValue=""
                              onChange={(e, editor) => {
                                const data = editor.getContent();
                                setDescription(data);
                              }}
                            />
                          )}
                        />
                      </div>
                      <p className="error-hint">
                        {errors.description?.type === "required" && (
                          <FormattedMessage id="PleaseEnterTheBlogDescription" />
                        )}
                      </p>
                    </div>
                  </Col>

                  {/* <Col lg={12}>
                    <div className="form-group">
                      <h5>اضافة كلمات دلالية</h5>
                      <div>
                        <Controller
                          control={control}
                          name="tags"
                          render={({
                            field: { onChange, value, selected },
                          }) => (
                            <CreatableSelect
                              isMulti
                              isRtl={true}
                              className="basic-single creatable-select"
                              classNamePrefix="select"
                              placeholder="اضف كلمات دلالية"
                              onChange={(selected) => {
                                const newTags = selected.map(
                                  (tag) => tag.label
                                );

                                setTags(newTags);
                              }}
                              options={blogTags.map((tag) => ({
                                value: tag,
                                label: tag,
                              }))}
                            />
                          )}
                        />
                      </div>
                      <p className="error-hint">
                        {errors.tags?.type === "required" &&
                          "يرجي ادخال الكلمات الدلالية"}
                      </p>
                    </div>
                  </Col> */}
                  <SeoForm errors={errors} register={register} />

                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        <FormattedMessage  id="AddTheBlog"/>
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddBlog;
