import server from "./server";

export const getThemesApi = async ({deviceType,page,limit}) => {
  const response = await server().get(`/themeComponentsType?page=${page}&limit=${limit}`, {
    headers: {
      deviceType: deviceType,
    },
  });
  return response.data;
};

export const getSingleThemeApi = async (id) => {
  const response = await server().get(`/themeComponentsType/${id}`);
  return response.data;
};

export const addThemeApi = async (payload) => {
  const response = await server().post("/themeComponentsType", payload?.data, {
    headers: {
      deviceType: payload?.deviceType,
    },
  });
  return response.data;
};

export const editThemeApi = async ({ id, data }) => {
  const response = await server().put(`/themeComponentsType/${id}`, data);
  return response.data;
};

export const deleteThemeApi = async (id) => {
  const response = await server().delete(`/themeComponentsType/${id}`);
  return response.data;
};
