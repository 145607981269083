import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { addItem, getItems } from "store/actions";
import { useNavigate } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import Loader from "components/shared/Loader";
import { useEffect } from "react";

const AddPermission = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state?.permissionsData);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    dispatch(getItems({ dataKey: "roles" }));
    dispatch(getItems({ dataKey: "groups" }));
  }, [dispatch]);

  const onSubmit = (data) => {
    dispatch(addItem({ data, navigate }));
  };

  if (loading) return <Loader />;
  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>
              <FormattedMessage id="addPermission" />
            </h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={10} md={12}>
                <Row>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="name" />
                      </h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder={formatMessage({ id: "name" })}
                          {...register("name", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.name?.type === "required" && (
                          <FormattedMessage id="required" />
                        )}
                      </p>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="description" />
                      </h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder={formatMessage({ id: "description" })}
                          {...register("description", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.description?.type === "required" && (
                          <FormattedMessage id="required" />
                        )}
                      </p>
                    </div>
                  </Col>
                </Row>
                <Col lg={4} xs={12}>
                  <div className="form-group">
                    <button type="submit" className="btn btn-blue">
                      <FormattedMessage id="add" />
                    </button>
                  </div>
                </Col>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPermission;
