import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import {
  addItemApi,
  deleteItemApi,
  editItemApi,
  getItemsApi,
  getSingleItemApi,
  toggleActiveUserApi,
} from "api/customApi";

// Login Redux States
import {
  ADD_ITEM,
  ADD_ITEM_USER,
  DELETE_ITEM,
  DELETE_ITEM_USER,
  EDIT_ITEM,
  EDIT_ITEM_USER,
  GET_ITEMS,
  GET_SINGLE_ITEM,
  Toggle_Active_USER,
} from "./actionTypes";

import {
  addItemFailure,
  addItemSuccess,
  addItemUserFailure,
  addItemUserSuccess,
  deleteItemFailure,
  deleteItemSuccess,
  deleteItemUserFailure,
  deleteItemUserSuccess,
  editItemFailure,
  editItemSuccess,
  editItemUserFailure,
  editItemUserSuccess,
  getItemsFailure,
  getItemsSuccess,
  getSingleItemFailure,
  getSingleItemSuccess,
  toggleActiveUserFailure,
  toggleActiveUserSuccess,
} from "./actions";
import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";

function* getItems({ payload }) {
  try {
    const { data } = yield call(getItemsApi, payload);
    yield put(getItemsSuccess(data));
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data || error });
    yield put(getItemsFailure(error?.response?.data?.errors?.[0]?.msg));
  }
}

function* addItem({ payload }) {
  try {
    const { data, success } = yield call(addItemApi, payload);
    yield put(addItemSuccess(data));
    // We should handle this navigation
    if (success) {
      // yield getItems({ payload });
      yield payload.navigate("/permissions/roles");
    }
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data || error });
    yield put(addItemFailure(error.response?.data?.message || error));
  }
}
function* addItemUser({ payload }) {
  try {
    const { data, success } = yield call(addItemApi, payload);
    yield put(addItemUserSuccess(data.superAdmin));
    // We should handle this navigation
    if (success) {
      yield payload.navigate();
    }
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data || error });
    yield put(addItemUserFailure(error.response?.data?.message || error));
  }
}

function* getSingleItem({ payload }) {
  try {
    const { data } = yield call(getSingleItemApi, payload);
    // yield new Promise((res) => setTimeout(res, 800));
    yield put(getSingleItemSuccess(data));
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data || error });
    yield put(getSingleItemFailure(error?.response?.data || error));
  }
}

function* editItem({ payload }) {
  try {
    yield call(editItemApi, payload);
    yield put(editItemSuccess(payload));
    // yield payload.navigate("/portalReservation");
    if (payload?.navigate) yield payload.navigate();
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data || error });
    yield put(editItemFailure(error?.response?.data || error));
  }
}

function* editItemUser({ payload }) {
  try {
    const { data } = yield call(editItemApi, payload);
    yield put(editItemUserSuccess(data.superAdmin));
    // yield payload.navigate("/portalReservation");
    if (payload?.navigate) yield payload.navigate();
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data || error });
    yield put(editItemUserFailure(error?.response?.data || error));
  }
}

function* toggleActiveUser({ payload }) {
  try {
    const { data } = yield call(toggleActiveUserApi, payload);
    yield put(toggleActiveUserSuccess(data.superAdmin));
    // yield payload.navigate("/portalReservation");
    if (payload?.navigate) yield payload.navigate();
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data || error });
    yield put(toggleActiveUserFailure(error?.response?.data || error));
  }
}

function* deleteItem({ payload }) {
  try {
    yield call(deleteItemApi, payload);
    yield put(deleteItemSuccess(payload));
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data || error });
    yield put(deleteItemFailure(error?.response?.data || error));
  }
}
function* deleteItemUser({ payload }) {
  try {
    yield call(deleteItemApi, payload);
    yield put(deleteItemUserSuccess(payload));
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data || error });
    yield put(deleteItemUserFailure(error?.response?.data || error));
  }
}

export function* watchGetItems() {
  yield takeEvery(GET_ITEMS, getItems);
}

export function* watchAddItem() {
  yield takeEvery(ADD_ITEM, addItem);
}
export function* watchAddItemUser() {
  yield takeEvery(ADD_ITEM_USER, addItemUser);
}

export function* watchGetSingleItem() {
  yield takeEvery(GET_SINGLE_ITEM, getSingleItem);
}

export function* watchEditItem() {
  yield takeEvery(EDIT_ITEM, editItem);
}
export function* watchEditItemUser() {
  yield takeEvery(EDIT_ITEM_USER, editItemUser);
}

export function* watchToggleUser() {
  yield takeEvery(Toggle_Active_USER, toggleActiveUser);
}

export function* watchDeleteItem() {
  yield takeEvery(DELETE_ITEM, deleteItem);
}

export function* watchDeleteItemUser() {
  yield takeEvery(DELETE_ITEM_USER, deleteItemUser);
}

function* banksSaga() {
  yield all([fork(watchGetItems)]);
  yield all([fork(watchGetSingleItem)]);
  yield all([fork(watchAddItem)]);
  yield all([fork(watchAddItemUser)]);
  yield all([fork(watchEditItem)]);
  yield all([fork(watchToggleUser)]);
  yield all([fork(watchEditItemUser)]);
  yield all([fork(watchDeleteItem)]);
  yield all([fork(watchDeleteItemUser)]);
}

export default banksSaga;
