import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { Row } from "react-bootstrap";
import { getPaymentMethods } from "store/actions";
// import PaymentCard from "components/Payment/PaymentCard";
import ControlArea from "components/Layout/ControlArea";
import paymentMethodIcon from "assets/svgs/paymentmethod.svg";
import Table from "./Table";
import { FormattedMessage } from "react-intl";
const Index = () => {
  const dispatch = useDispatch();
  const { paymentMethods } = useSelector((state) => state.paymentMethods);

  useEffect(() => {
    dispatch(getPaymentMethods());
  }, [dispatch]);

  // const payCards = paymentMethods.map((payCard) => {
  //   return <PaymentCard key={payCard.id} payCard={payCard} />;
  // });

  return (
    <>
      <div className="stores-wrap">
        <ControlArea
          btnTxt={<FormattedMessage id="addPaymentMethod" />}
          cardTxt={<FormattedMessage id="paymentMethods" />}
          icon={paymentMethodIcon}
          url="/paymentmethods/addpaymentmethod"
        />
        {/* <Row>{payCards}</Row> */}
        <Table data={paymentMethods} />
      </div>
    </>
  );
};

export default Index;
