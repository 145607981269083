import React, { useEffect } from "react";
import MainSettings from "./infoSettings/mainSettings";
import SocialsSettings from "./infoSettings/SocialsSettings";
// import MailSettings from './MailSettings'
import { getAllSettings } from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { Tab, Tabs } from "react-bootstrap";
import SeoSettings from "./infoSettings/SeoSettings";
// import MessageSettings from "./messagesSettings";
// import AccountSettings from "./accountSettings";

const InfoSettings = () => {
  const dispatch = useDispatch();

  const { mainSettings, socialSettings, seoSettings } = useSelector(
    (state) => state.settings
  );

  const { formatMessage } = useIntl();

  useEffect(() => {
    dispatch(getAllSettings());
  }, [dispatch]);

  // useEffect(() => {
  //   if (error) {
  //     toast.error(<FormattedMessage id={"sendingErrorMessage"} />, {
  //       position: "bottom-left",
  //       autoClose: 2000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     });
  //   }
  // }, [error]);

  // if (loading) return <Loader />;

  return (
    <>
      <div className="acc-form settings_tabs">
        <Tabs
          defaultActiveKey="mainSettings"
          id="home_page_tabs"
          className="home_page_tabs__"
        >
          <Tab
           
            eventKey="mainSettings"
            title={formatMessage({ id: "mainSettings" })}
          >
            <MainSettings
              settings={mainSettings}
              title={<FormattedMessage id={"mainSettings"} />}              
            />
          </Tab>

          <Tab
            eventKey="socialSettings"
            title={formatMessage({ id: "socialSettings" })}
          >
            <SocialsSettings
              settings={socialSettings}
              title={<FormattedMessage id={"socialSettings"} />}
            />
          </Tab>
          <Tab
            eventKey="seoSettings"
            title={formatMessage({ id: "seoSettings" })}
          >
            <SeoSettings
              settings={seoSettings}
              title={<FormattedMessage id={"seoSettings"} />}
            />
          </Tab>

          {/* <Tab
            eventKey="messagesSettings"
            title={formatMessage({ id: "messagesSettings" })}
          >
            <MessageSettings
              settings={messagesSettings}
              title={<FormattedMessage id={"messagesSettings"} />}
            />
          </Tab> */}

          {/* <Tab
            eventKey="accountsSettings"
            title={formatMessage({ id: "accountsSettings" })}
          >
            <AccountSettings
              settings={accountsSettings}
              title={<FormattedMessage id={"accountsSettings"} />}
            />
          </Tab> */}
          {/* <Tab eventKey="mailSettings" title={formatMessage({ id: "mailSettings" })}   >
            <MailSettings settings={mailSettings} title={<FormattedMessage id={"mailSettings"} />} />
          </Tab> */}
        </Tabs>
      </div>
    </>
  );
};

export default InfoSettings;
