import { useState, useEffect, useMemo } from "react";
import { Col, Row } from "react-bootstrap";

// import { ReactComponent as DeleteIcon } from "assets/svgs/product/trash.svg";
// import { ReactComponent as RemoveIcon } from "assets/svgs/close-icon.svg";
import { useSearchParams } from "react-router-dom";
import { getId } from "helpers/functions";
import { useIntl } from "react-intl";
import "styles/cards.scss";
import Card from "./Card";
import BootyPagination from "../shared/Pagination";
// import { useIntl } from "react-intl";

const CardsList = ({
  listView,
  metadata,
  filteredItems,
  isCheck,
  handleClick,
  // duplicateProduct,
}) => {
  const { locale } = useIntl();
  const [searchParams] = useSearchParams();
  const queryParams = Object.fromEntries([...searchParams]);
  const paramsArray = Object.entries(queryParams);

  const [cards, setCards] = useState();

  const [page, setPage] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const [params, setParams] = useState([]);

  const productsAsStr = JSON.stringify(filteredItems);

  useEffect(() => {
    setCards(filteredItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsAsStr]);

  // const handleDeleteProduct = () => {
  //   Swal.fire({
  //     title: `هل تريد حذف ${isCheck?.length} منتجات!`,
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#2a89f3",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "نعم",
  //     cancelButtonText: "لا",
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       if (isCheck?.length > 0)
  //         dispatch(
  //           // passing the selected products + the search params for pagination info
  //           deleteProducts({
  //             products: isCheck,
  //             searchParams: Object.fromEntries([...searchParams]),
  //           })
  //         );
  //       setIsCheck([]);
  //     }
  //   });
  // };
  // const moveCard = useCallback(
  //   (dragIndex, hoverIndex) =>
  //     setCards((prevCards) =>
  //       update(prevCards, {
  //         $splice: [
  //           [dragIndex, 1],
  //           [hoverIndex, 0, prevCards[dragIndex]],
  //         ],
  //       })
  //     ),
  //   []
  // );
  const renderProducts = useMemo(
    () =>
      cards?.map((product, index) => {
        return (
          <Col xs={12} sm={6} md={6} lg={6} xl={4} xxl={3} key={getId(product)}>
            <Card
              data={product}
              isCheck={isCheck}
              handleClick={handleClick}
              // duplicateProduct={duplicateProduct}
            />
          </Col>
        );
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cards, handleClick, isCheck, locale]
  );

  useEffect(() => {
    setPage(metadata?.pagination?.page || 1);
  }, [metadata]);

  const totalPages = Math.ceil(
    metadata?.totalResults / metadata?.pagination?.limit
  );

  useEffect(() => {
    setParams(paramsArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  // const handleSaveOrder = () => {
  //   dispatch(
  //     orderProducts({
  //       products: filteredItems?.map((card, index) => ({
  //         id: getId(card),
  //         orderInCategory: index,
  //       })),
  //     })
  //   );
  // };

  // const saveBtn = useMemo(
  //   () =>
  //     JSON?.stringify(filteredItems) !== JSON?.stringify(cards) && (
  //       <div className="d-flex justify-content-between align-items-center my-2 border border-secondary border-1 rounded-pill p-2">
  //         <span className="dark-white mx-2">
  //           <FormattedMessage id="saveChanges" />
  //         </span>
  //         <button className="btn btn-blue" onClick={handleSaveOrder}>
  //           <FormattedMessage id="save" />
  //         </button>
  //       </div>
  //     ),
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [filteredItems, cards]
  // );

  return (
    <div className={listView ? "productsList listView" : "productsList"}>
      {/* {saveBtn} */}

      <Row>
        {filteredItems?.length > 0 ? (
          renderProducts
        ) : (
          <div className="no-data alert alert-warning text-center m-0 mt-4">
            <p>لا توجد منتجات</p>
          </div>
        )}
      </Row>

      {page < totalPages &&
        filteredItems?.length > metadata?.pagination?.limit && (
          <BootyPagination metaData={metadata} />
        )}
      {/* {loading && <StickyLoader fill="#395cf5" />} */}
    </div>
  );
};

export default CardsList;
