import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { Accordion, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { addItem, getItems } from "store/actions";
import Loader from "components/shared/Loader";
import { ReactComponent as DashboardIcon } from "assets/svgs/dashboard-active.svg";

const AddRole = () => {
  const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { permissionsData, loading } = useSelector(
    (state) => state?.permissionsData
  );
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState({});
  useEffect(() => {
    dispatch(getItems({ dataKey: "permissions" }));
  }, [dispatch]);

  const onSubmit = (data) => {
    data.permissions = selectedPermissions;
    dispatch(addItem({ dataKey: "roles", data, navigate }));
  };

  const handleSelectAll = (item, isChecked) => {
    const newSelectedPermissions = [...selectedPermissions];
    item.permissions.forEach((permission) => {
      if (isChecked) {
        if (!newSelectedPermissions.includes(permission.slug)) {
          newSelectedPermissions.push(permission.slug);
        }
      } else {
        const index = newSelectedPermissions.indexOf(permission.slug);
        if (index > -1) {
          newSelectedPermissions.splice(index, 1);
        }
      }
    });
    setSelectedPermissions(newSelectedPermissions);
    setSelectedGroups({
      ...selectedGroups,
      [item.group.name[locale]]: isChecked,
    });
  };

  const handlePermissionChange = (permissionSlug, isChecked) => {
    if (isChecked) {
      setSelectedPermissions([...selectedPermissions, permissionSlug]);
    } else {
      setSelectedPermissions(
        selectedPermissions.filter((slug) => slug !== permissionSlug)
      );
    }
  };

  if (loading) return <Loader />;

  return (
    <>
      <div className="acc-form permissions">
        <div className="breadcrumb">
          <h2 className="active">
            <DashboardIcon />
            <FormattedMessage id="dashboard" />
          </h2>
          <h2>&gt;</h2>
          <h2>
            <FormattedMessage id="permissions" />
          </h2>
          <h2>&gt;</h2>
          <h2>
            <FormattedMessage id="roles" />
          </h2>
          <h2>&gt;</h2>
          <h2>
            <FormattedMessage id="addRole" />
          </h2>
        </div>
        <div className="titlePage-div">
          <h2 className="title">
            <FormattedMessage id="newRole" />
          </h2>
          <p className="description">
            <FormattedMessage id="rolesAreAssignedToGrantSpecificPermissionsToDifferentTypesOfUsersWithinYourWorkspace. ThisProvidesTheWorkspaceOwnerAndAdministratorsGreaterControlOverWhoCanPostWhat, EspeciallyForLargerProjects." />
          </p>
        </div>

        <div className="addRole-div">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col xs={12} md={12} lg={6} xl={6}>
                <div className="input-div">
                  <label>
                    <FormattedMessage id="name" />
                  </label>
                  <input
                    type="text"
                    placeholder={formatMessage({ id: "name" })}
                    maxLength="64"
                    {...register("name", {
                      required: true,
                    })}
                  />
                </div>
                {errors?.name && (
                  <p className="error-hint">
                    <FormattedMessage id="required" />
                  </p>
                )}
              </Col>
              <Col xs={12} md={12} lg={6} xl={6}>
                <div className="input-div">
                  <label>
                    <FormattedMessage id="description" />
                  </label>
                  <input
                    type="text"
                    placeholder={formatMessage({ id: "description" })}
                    maxLength="400"
                    {...register("description", {
                      required: true,
                    })}
                  />
                </div>
                {errors?.description && (
                  <p className="error-hint">
                    <FormattedMessage id="required" />
                  </p>
                )}
              </Col>

              <Col xs={12}>
                <h3 className="permissionsTitle">
                  <FormattedMessage id="permissions" />
                </h3>
                <Accordion
                  defaultActiveKey={0}
                  transition={true}
                  timeout={1000}
                >
                  {permissionsData?.permissions?.map((item, index) => (
                    <Accordion.Item eventKey={index} key={index}>
                      <Accordion.Header>
                        <div
                          key={index}
                          className="d-flex gap-4 align-items-center selectAll"
                        >
                          <label>
                            <input
                              type="checkbox"
                              checked={
                                selectedGroups[item.group.name[locale]] || false
                              }
                              onClick={(e) => e.stopPropagation()}
                              onChange={(e) => {
                                handleSelectAll(item, e.target.checked);
                              }}
                            />
                          </label>
                        </div>
                        {item?.group?.name?.[locale]}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="allRoleDivCheck">
                          {item?.permissions?.map((permission, index) => (
                            <div
                              key={index}
                              className="d-flex gap-4 align-items-center oneRoleDivCheck"
                            >
                              <label>
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    handlePermissionChange(
                                      permission?.slug,
                                      e.target.checked
                                    )
                                  }
                                  checked={selectedPermissions.includes(
                                    permission?.slug
                                  )}
                                />

                                <h5 className="mx-2 d-inline-block mb-0">
                                  {permission?.description?.[locale]}
                                </h5>
                              </label>
                            </div>
                          ))}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </Col>
              <Col lg={4} xs={12}>
                <div className="form-group">
                  <button type="submit" className="btn btn-blue">
                    <FormattedMessage id="add" />
                  </button>
                </div>
              </Col>
            </Row>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddRole;
