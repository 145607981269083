import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import CreatableSelect from "react-select/creatable";

import {
  editThemesTemplate,
  getCategories,
  getSingleThemesTemplate,
} from "store/actions";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Select from "react-select";
import { FormattedMessage, useIntl } from "react-intl";
import server from "api/server";
import imageUpload from "assets/svgs/imageUpload.svg";
import Loader from "components/shared/Loader";
import { getPublicImage } from "helpers/functions";
const EditTheme = () => {
  const [selectedImage, setSelectedImage] = useState({
    preview: null,
    path: null,
  });
  const { locale } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const deviceType = searchParams.get("deviceType");
  const intl = useIntl();
  const { categories } = useSelector((state) => state.categories);
  const { singleTemplate, loading } = useSelector((state) => state?.templates);

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    dispatch(getCategories());
    dispatch(getSingleThemesTemplate(id));
  }, [dispatch, id]);

  useEffect(() => {
    reset({
      name: singleTemplate?.name,
      image: singleTemplate?.image,
      category: singleTemplate?.category?.id,
      description: singleTemplate?.description,
      tags: singleTemplate?.tags,
      price: singleTemplate?.price,
    });
  }, [singleTemplate, reset]);

  const imageChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      const { data } = await server().post("/upload", formData);
      setSelectedImage({
        preview: e.target.files[0],
        path: getPublicImage(data.data.files[0]),
      });
    }
  };

  const removeSelectedImage = () => {
    setSelectedImage();
  };

  const onSubmit = (data) => {
    if (selectedImage && selectedImage.path) data["image"] = selectedImage.path;
    // data.image = "uploads/" + data.image.split("/").pop();
    dispatch(editThemesTemplate({ id, data, navigate, deviceType }));
  };

  const renderListCategories = () => {
    return categories?.map((category) => ({
      label: category.name?.[locale],
      value: category?.name?.[locale],
      id: category?.id,
    }));
  };

  if (loading) return <Loader />;
  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>
              <FormattedMessage id="EditTemplateData" />
            </h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={10} md={12}>
                <Row>
                  <Col xl={10} md={12}>
                    <Row>
                      <Col lg={12}>
                        <div className="form-group required">
                          <h5>
                            <FormattedMessage id="TemplateImage" />{" "}
                          </h5>{" "}
                          <div className="image-uploader image-contain">
                            <div className="img-pat">
                              <img
                                src={imageUpload}
                                width={46}
                                height={46}
                                alt=""
                              />
                            </div>
                            <label>
                              {selectedImage && (
                                <div>
                                  <Controller
                                    control={control}
                                    name="image"
                                    render={() => {
                                      return (
                                        <img
                                          src={
                                            selectedImage &&
                                            selectedImage.preview
                                              ? URL.createObjectURL(
                                                  selectedImage.preview
                                                )
                                              : singleTemplate?.image
                                          }
                                          width={160}
                                          height={160}
                                          alt=""
                                        />
                                      );
                                    }}
                                  />
                                  <button
                                    className="removeImg"
                                    onClick={removeSelectedImage}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="12"
                                      height="12"
                                      fill="currentColor"
                                      className="bi bi-x-lg"
                                      viewBox="0 0 16 16"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
                                      />
                                      <path
                                        fillRule="evenodd"
                                        d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
                                      />
                                    </svg>
                                  </button>
                                </div>
                              )}
                              <input
                                accept="image/*"
                                type="file"
                                onChange={imageChange}
                              />
                            </label>
                            <p className="error-hint">
                              {errors.image?.type === "required" && (
                                <formatMessage id="PleaseSelectTheTagImage" />
                              )}
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col lg={6} xs={12}>
                        <div className="form-group required">
                          <h5>
                            <FormattedMessage id="TemplateNameInArabic" />{" "}
                          </h5>{" "}
                          <div>
                            <input
                              type="text"
                              className="form-control form-outline"
                              placeholder={intl.formatMessage({
                                id: "TemplateNameInArabic",
                              })}
                              {...register("name[ar]", { required: true })}
                            />
                          </div>
                          <p className="error-hint">
                            {errors?.name?.["ar"]?.type === "required" && (
                              <FormattedMessage id="PleaseEnterTheTemplateNameInArabic" />
                            )}
                          </p>
                        </div>
                      </Col>
                      <Col lg={6} xs={12}>
                        <div className="form-group required">
                          <h5>
                            <FormattedMessage id="TemplateNameInEnglish" />{" "}
                          </h5>{" "}
                          <div>
                            <input
                              type="text"
                              className="form-control form-outline"
                              placeholder={intl.formatMessage({
                                id: "TemplateNameInEnglish",
                              })}
                              {...register("name[en]", { required: true })}
                            />
                          </div>
                          <p className="error-hint">
                            {errors?.name?.["en"]?.type === "required" && (
                              <FormattedMessage id="PleaseEnterTheTemplateNameInEnglish" />
                            )}
                          </p>
                        </div>
                      </Col>
                      <Col lg={6} xs={12}>
                        <div className="form-group required">
                          <h5>
                            <FormattedMessage id="TemplatePrice" />
                          </h5>{" "}
                          <div>
                            <input
                              type="number"
                              className="form-control form-outline"
                              placeholder={intl.formatMessage({
                                id: "TemplatePrice",
                              })}
                              {...register("price", {
                                required: (
                                  <FormattedMessage id="PleaseEnterTheTemplatePrice" />
                                ),
                                validate: (value) =>
                                  value > 0 || (
                                    <FormattedMessage id="PleaseEnterAPositiveNumber" />
                                  ),
                              })}
                            />
                            {errors.price && (
                              <p className="error-hint">
                                {errors.price.message}
                              </p>
                            )}
                          </div>
                        </div>
                      </Col>
                      <Col lg={6} xs={12}>
                        <div className="form-group required">
                          <h5>
                            <FormattedMessage id="TemplateCategory" />
                          </h5>{" "}
                          <div>
                            <Controller
                              control={control}
                              name="category"
                              render={({
                                field: { onChange, value, selected },
                              }) => (
                                <Select
                                  isRtl={true}
                                  isSearchable={false}
                                  className="basic-single"
                                  classNamePrefix="select"
                                  options={renderListCategories()}
                                  placeholder={intl.formatMessage({
                                    id: "ChooseTheCategory",
                                  })}
                                  isMulti={false}
                                  defaultValue={renderListCategories()?.filter(
                                    (value) =>
                                      value.id === singleTemplate?.category?.id
                                  )}
                                  onChange={(selected) => {
                                    onChange(selected?.id);
                                  }}
                                />
                              )}
                            />
                          </div>
                          <p className="error-hint">
                            {errors?.category?.type === "required" && (
                              <formatMessage id="PleaseEnterTheCategory" />
                            )}
                          </p>
                        </div>
                      </Col>
                      <Col lg={6} xs={12}>
                        <div className="form-group required">
                          <h5>
                            <FormattedMessage id="TemplateDescriptionInArabic" />{" "}
                          </h5>{" "}
                          <div>
                            <textarea
                              type="text"
                              className="form-control form-outline"
                              placeholder={intl.formatMessage({
                                id: "TemplateDescriptionInArabic",
                              })}
                              {...register("description[ar]", {
                                required: true,
                              })}
                            />
                          </div>
                          <p className="error-hint">
                            {errors?.description?.["ar"]?.type ===
                              "required" && (
                              <FormattedMessage id="PleaseEnterTheTemplateDescriptionInArabic" />
                            )}
                          </p>
                        </div>
                      </Col>
                      <Col lg={6} xs={12}>
                        <div className="form-group required">
                          <h5>
                            <FormattedMessage id="TemplateDescriptionInEnglish" />{" "}
                          </h5>{" "}
                          <div>
                            <textarea
                              type="text"
                              className="form-control form-outline"
                              placeholder={intl.formatMessage({
                                id: "TemplateDescriptionInEnglish",
                              })}
                              {...register("description[en]", {
                                required: true,
                              })}
                            />
                          </div>
                          <p className="error-hint">
                            {errors?.description?.["en"]?.type ===
                              "required" && (
                              <FormattedMessage id="PleaseEnterTheTemplateDescriptionInEnglish" />
                            )}
                          </p>
                        </div>
                      </Col>
                   
                      <Col lg={12}>
                        <div className="form-group">
                          <h5>
                            <FormattedMessage id="AddRelatedKeywords" />{" "}
                          </h5>{" "}
                          <div>
                            <Controller
                              control={control}
                              name="tags"
                              render={({
                                field: { onChange, value, selected },
                              }) => (
                                <CreatableSelect
                                  isMulti
                                  isRtl={true}
                                  className="basic-single creatable-select"
                                  classNamePrefix="select"
                                  placeholder={intl.formatMessage({
                                    id: "PleaseEnterTheRelatedkeywords",
                                  })}
                                  onChange={(selected) => {
                                    onChange(selected?.map((e) => e?.value));
                                  }}
                                  value={value?.map((tag) => ({
                                    value: tag,
                                    label: tag,
                                  }))}
                                />
                              )}
                            />
                          </div>
                          {/* <p className="error-hint">
                            {errors.tags?.type === "required" &&
                              "يرجي ادخال الكلمات الدلالية"}
                          </p> */}
                        </div>
                      </Col>

                      <Col lg={4} xs={12}>
                        <div className="form-group">
                          <button type="submit" className="btn btn-blue">
                            <FormattedMessage id="AddTemplate" />{" "}
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditTheme;
