import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSinglePortalTechnicalSupport } from "store/actions";
import { useParams } from "react-router-dom";
import Loader from "../../components/shared/Loader";
import { FormattedDate, FormattedMessage } from "react-intl";

const ShowTechnical = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { singlePortalTechnicalSupport, loading } = useSelector(
    (state) => state?.portalTechnicalSupport
  );

  useEffect(() => {
    dispatch(getSinglePortalTechnicalSupport(id));
  }, [dispatch, id]);

  if (loading) return <Loader />;
  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>
              <FormattedMessage id="showInquiry" />
            </h4>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-bordered show-table">
                <tbody>
                  <tr>
                    <td>
                      <FormattedMessage id="createdAt" />
                    </td>
                    <td>
                      <FormattedDate
                        value={singlePortalTechnicalSupport?.createdAt}
                        year="numeric"
                        month="long"
                        day="numeric"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <FormattedMessage id="Name" />
                    </td>
                    <td>{singlePortalTechnicalSupport?.name} </td>
                  </tr>
                  <tr>
                    <td>
                      <FormattedMessage id="email" />
                    </td>
                    <td>
                      <a href={`mailto:${singlePortalTechnicalSupport?.email}`}>
                        {singlePortalTechnicalSupport?.email}
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <FormattedMessage id="Subject" />
                    </td>
                    <td>{singlePortalTechnicalSupport?.subject} </td>
                  </tr>
                  <tr>
                    <td>
                      <FormattedMessage id="Message" />
                    </td>
                    <td>{singlePortalTechnicalSupport?.message} </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShowTechnical;
