import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { editItem, getItems, getSingleItem } from "store/actions";
import { useNavigate, useParams } from "react-router-dom";
import { Accordion, Col, Row } from "react-bootstrap";

import Loader from "../../components/shared/Loader";
import { FormattedMessage, useIntl } from "react-intl";

const EditRole = () => {
  const { id } = useParams();
  const { formatMessage, locale } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState({});

  const { singlePermissionData, permissionsData, loading } = useSelector(
    (state) => state?.permissionsData
  );
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    dispatch(getItems({ dataKey: "permissions/?limit=1000" }));
    dispatch(getSingleItem({ dataKey: "roles", id }));
  }, [dispatch, id]);

  useEffect(() => {
    if (singlePermissionData?.role) {
      reset(singlePermissionData.role);
      setSelectedPermissions(singlePermissionData.role.permissions || []);
    }
  }, [reset, singlePermissionData]);
  useEffect(() => {
    setValue("permissions", selectedPermissions);
  }, [selectedPermissions, setValue]);

  // const unselectedPermissions = permissions
  //   ?.map((p) => p?.name)
  //   ?.filter((e) => !singleRole?.permissions?.includes(e));

  const handleSelectAll = (item, isChecked) => {
    const newSelectedPermissions = [...selectedPermissions];
    item.permissions.forEach((permission) => {
      if (isChecked) {
        if (!newSelectedPermissions.includes(permission.slug)) {
          newSelectedPermissions.push(permission.slug);
        }
      } else {
        const index = newSelectedPermissions.indexOf(permission.slug);
        if (index > -1) {
          newSelectedPermissions.splice(index, 1);
        }
      }
    });
    setSelectedPermissions(newSelectedPermissions);
    setSelectedGroups({
      ...selectedGroups,
      [item.group.name[locale]]: isChecked,
    });
  };

  const handlePermissionChange = (permissionSlug, isChecked) => {
    if (isChecked) {
      setSelectedPermissions((prev) => [...prev, permissionSlug]);
    } else {
      setSelectedPermissions((prev) =>
        prev.filter((slug) => slug !== permissionSlug)
      );
    }
  };
  const renderRolesPermissions = useCallback(
    (item) =>
      item?.permissions?.map((permission, index) => (
        <div
          key={index}
          className="d-flex gap-4 align-items-center oneRoleDivCheck"
        >
          <label>
            <input
              type="checkbox"
              onChange={(e) =>
                handlePermissionChange(permission?.slug, e.target.checked)
              }
              checked={selectedPermissions.includes(permission?.slug)}
            />

            <h5 className="mx-2 d-inline-block mb-0 cursor-pointer">
              {permission?.description?.[locale]}
            </h5>
          </label>
        </div>
      )),
    [locale, selectedPermissions]
  );

  const renderRoles = useMemo(
    () =>
      permissionsData?.permissions?.map((item, index) => {
        const currentGroupSelectedPermissions = selectedPermissions?.filter(
          (permis) =>
            item?.permissions?.find((itemPermis) => itemPermis?.slug === permis)
        );

        return (
          <Accordion.Item eventKey={index} key={index}>
            <Accordion.Header
            // aria-expanded={currentGroupSelectedPermissions?.length > 0}
            // ref={(e) =>
            //   currentGroupSelectedPermissions?.length > 0
            //     ? e?.children?.[0]?.click()
            //     : ""
            // }
            >
              <div
                key={index}
                className="d-flex gap-4 align-items-center selectAll"
              >
                <label>
                  <input
                    type="checkbox"
                    checked={
                      currentGroupSelectedPermissions?.length >=
                      item?.permissions?.length
                    }
                    // defaultChecked={
                    //   currentGroupSelectedPermissions?.length ===
                    //   item?.permissions?.length
                    // }
                    onClick={(e) => e.stopPropagation()}
                    onChange={(e) => {
                      handleSelectAll(item, e.target.checked);
                    }}
                  />
                </label>
              </div>
              {item?.group?.name?.[locale]}
            </Accordion.Header>
            <Accordion.Body
              className={
                currentGroupSelectedPermissions?.length > 0 ? "show" : ""
              }
            >
              <div className="allRoleDivCheck">
                {renderRolesPermissions(item)}
              </div>
            </Accordion.Body>
          </Accordion.Item>
        );
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      locale,
      permissionsData?.permissions,
      selectedGroups,
      singlePermissionData?.role?.permissions,
      selectedPermissions,
    ]
  );

  const onSubmit = (data) => {
    dispatch(
      editItem({
        dataKey: "roles",
        id,
        data,
        navigate: () => navigate("/permissions/roles"),
      })
    );
  };

  if (loading) return <Loader />;
  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>
              <FormattedMessage id="editRole" />
            </h4>
          </div>
          <div className="card-body permissions">
            <div className="addRole-div">
              <form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col xs={12} md={12} lg={6} xl={6}>
                    <div className="input-div">
                      <label>
                        <FormattedMessage id="name" />
                      </label>
                      <input
                        type="text"
                        placeholder={formatMessage({ id: "name" })}
                        maxLength="64"
                        {...register("name", {
                          required: true,
                        })}
                      />
                    </div>
                    {errors?.name && (
                      <p className="error-hint">
                        <FormattedMessage id="required" />
                      </p>
                    )}
                  </Col>
                  <Col xs={12} md={12} lg={6} xl={6}>
                    <div className="input-div">
                      <label>
                        <FormattedMessage id="description" />
                      </label>
                      <input
                        type="text"
                        placeholder={formatMessage({ id: "description" })}
                        maxLength="400"
                        {...register("description", {
                          required: true,
                        })}
                      />
                    </div>
                    {errors?.description && (
                      <p className="error-hint">
                        <FormattedMessage id="required" />
                      </p>
                    )}
                  </Col>

                  <Col xs={12}>
                    <h3 className="permissionsTitle">
                      <FormattedMessage id="permissions" />
                    </h3>
                    <Accordion
                      defaultActiveKey={0}
                      transition={true}
                      timeout={1000}
                      alwaysOpen
                    >
                      {renderRoles}
                    </Accordion>
                  </Col>
                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        <FormattedMessage id="edit" />
                      </button>
                    </div>
                  </Col>
                </Row>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditRole;
