import { getId } from "helpers/functions";
import {
  GET_USERS,
  GET_USERS_FAILURE,
  GET_USERS_SUCCESS,
  ADD_USER,
  ADD_USER_SUCCESS,
  ADD_USER_FAILURE,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  GET_SINGLE_USER,
  GET_SINGLE_USER_FAILURE,
  GET_SINGLE_USER_SUCCESS,
} from "./actionTypes";

const initialState = {
  users: [],
  loading: false,
  error: "",
  singleUser: {},
  metadata:{}
};

const stores = (state = initialState, action) => {
  switch (action.type) {
    case GET_USERS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_USERS_SUCCESS:
      state = {
        ...state,
        loading: false,
        users: action.payload.users,
      };
      break;
    case GET_USERS_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case GET_SINGLE_USER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_SINGLE_USER_SUCCESS:
      state = {
        ...state,
        loading: false,
        singleUser: action.payload.user,
      };
      break;
    case GET_SINGLE_USER_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case ADD_USER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case ADD_USER_SUCCESS:
      state = {
        ...state,
        loading: false,
        users: action.payload.users,
      };
      break;
    case ADD_USER_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    case DELETE_USER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_USER_SUCCESS:
      const usersAfterDeleteing = [
        ...state?.users?.filter((user) => getId(user) !== action.payload),
      ];

      state = {
        ...state,
        loading: false,
        users: usersAfterDeleteing,
      };
      break;
    case DELETE_USER_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
