import ControlArea from "components/Layout/ControlArea";
import React from "react";
import { useSelector } from "react-redux";
import banksIcon from "assets/svgs/bank.svg";
import Table from "./Table";
import BootyPagination from "components/shared/Pagination";
import { FormattedMessage } from "react-intl";
const Index = () => {
  const { banks, metadata } = useSelector((state) => state.banks);
  return (
    <>
      <div className="stores-wrap">
        <ControlArea
         
          btnTxt={<FormattedMessage id="addNewBank" />}
          cardTxt={<FormattedMessage id="allBanks" />}
          icon={banksIcon}
          url="/banks/add"
        />
        <Table data={banks} />
        <BootyPagination metaData={metadata} />
      </div>
    </>
  );
};

export default Index;
