import ControlArea from "components/Layout/ControlArea";
import React from "react";
import { useSelector } from "react-redux";
import themesIcon from "assets/svgs/themes.svg";
import Table from "./Table";
import { useSearchParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
const Index = () => {
  const { templates } = useSelector((state) => state.templates);
  const [searchParams] = useSearchParams();
  const deviceType = searchParams.get("deviceType");

  return (
    <>
      <div className="stores-wrap">
        <ControlArea
          btnTxt={<FormattedMessage id="AddTemplate" />}
          cardTxt={<FormattedMessage id="templates" />}
          icon={themesIcon}
          url={`/themes/templates/add?deviceType=${deviceType}`}
        />
        <Table data={templates} />
      </div>
    </>
  );
};

export default Index;
