import React, { useState, useEffect } from "react";
import Table from "./Table";

import { getPortalTechnicalSupports } from "store/actions";

import { useLocation } from "react-router-dom";
import { PaginationControl } from "react-bootstrap-pagination-control";
import { useSelector, useDispatch } from "react-redux";
const PortalTechnicalSupport = () => {
  const { portalTechnicalSupports , metadata} = useSelector(
    (state) => state.portalTechnicalSupport
  );
  const dispatch = useDispatch();

 
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const pageNumberFromQuery = parseInt(queryParams.get("page")) || 1;
  const pageSizeFromQuery = parseInt(queryParams.get("limit")) || 10;

  const [page, setPage] = useState(pageNumberFromQuery);
  const [limit, setLimit] = useState(pageSizeFromQuery);

  useEffect(() => {
    // Fetch users when page or page size changes
    dispatch(getPortalTechnicalSupports({ page, limit }));

    // Update URL with pageNumber and pageSize
    const newUrl = new URL(window.location.href);
    newUrl.searchParams.set("page", page);
    newUrl.searchParams.set("limit", limit);
    window.history.replaceState({}, "", newUrl);
  }, [dispatch, page, limit]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (e) => {
    setLimit(Number(e.target.value));
  };
  return (
    <>
      <div className="stores-wrap">
        {/* <ControlArea
          btnTxt={formatMessage({ id: "portalSupport" })}
          cardTxt="التصنيفات"
          icon={categoriesIcon}
          url="/portalTechnicalSupport/add"
        /> */}
        <Table data={portalTechnicalSupports} />
        <div className="table--pagination">
          <PaginationControl
            page={page}
            between={2}
            total={metadata?.totalResults || 1}
            limit={metadata?.pagination?.limit || 1}
            changePage={handlePageChange}
            ellipsis={2}
          />
          <select
            className="form-control form-select"
            value={limit}
            onChange={handlePageSizeChange}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </div>
      </div>
    </>
  );
};

export default PortalTechnicalSupport;
