import React from "react";
import { useSelector } from "react-redux";
import Table from "./Table";
import BootyPagination from "components/shared/Pagination";

const PortalReservations = () => {
  const { portalReservations, metadata } = useSelector(
    (state) => state.portalReservations
  );

  return (
    <>
      <div className="stores-wrap">
        {/* <ControlArea
          btnTxt={formatMessage({ id: "portalSupport" })}
          cardTxt="التصنيفات"
          icon={categoriesIcon}
          url="/portalTechnicalSupport/add"
        /> */}
        <Table data={portalReservations} />
        <BootyPagination metaData={metadata} />
      </div>
    </>
  );
};

export default PortalReservations;
