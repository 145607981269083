import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap";

import { addPackage, getFeatures } from "store/actions";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { FormattedMessage, useIntl } from "react-intl";
import Loader from "components/shared/Loader";
import ImageUpload from "components/shared/ImageUpload";
const AddPackage = () => {
  const { locale } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();
  const { features, loading: featuresLoading } = useSelector(
    (state) => state.features
  );
  const { loading } = useSelector((state) => state.packages);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  useEffect(() => {
    dispatch(getFeatures());
  }, [dispatch]);

  const onSubmit = (data) => {
    dispatch(addPackage({ data, navigate }));
  };

  const renderListFeatures = () => {
    return features?.map((feature) => ({
      label: feature.name?.[locale],
      value: feature?.name?.[locale],
      id: feature?.id,
    }));
  };

  if (loading || featuresLoading) return <Loader />;
  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>
              <FormattedMessage id="addANewPackage" />
            </h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={10} md={12}>
                <Row>
                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="icon" />
                      </h5>
                      <div>
                        <Controller
                          control={control}
                          name="icon"
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <ImageUpload
                              selectedImage={value}
                              setSelectedImage={onChange}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="packageNameInArabic" />
                      </h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder={intl.formatMessage({
                            id: "packageNameInArabic",
                          })}
                          {...register("name[ar]", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.name?.["ar"]?.type === "required" && (
                          <FormattedMessage id="PleaseEnterThePackageNameInArabic" />
                        )}
                      </p>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="packageNameInEnglish" />
                      </h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder={intl.formatMessage({
                            id: "packageNameInEnglish",
                          })}
                          {...register("name[en]", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.name?.["en"]?.type === "required" && (
                          <FormattedMessage id="PleaseEnterThePackageNameInEnglish" />
                        )}
                      </p>
                    </div>
                  </Col>
                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="PackagePrice" />
                      </h5>
                      <div>
                        {/* <input
                          type="number"
                          className="form-control form-outline"
                          placeholder="سعر الباقة "
                          {...register("price", { required: true })}
                        /> */}

                        <input
                          type="number"
                          className="form-control form-outline"
                          placeholder={intl.formatMessage({
                            id: "PackagePrice",
                          })} // min="1"
                          {...register("price", {
                            required: (
                              <FormattedMessage id="PleaseEnterThePackagePrice" />
                            ),
                            validate: (value) =>
                              value > 0 || (
                                <FormattedMessage id="PleaseEnterAPositiveNumber" />
                              ),
                          })}
                        />
                        {errors.price && (
                          <p className="error-hint">{errors.price.message}</p>
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5>
                        {" "}
                        <FormattedMessage id="PackageFeatures" />
                      </h5>{" "}
                      <div>
                        <Controller
                          control={control}
                          name="features"
                          rules={{
                            required: (
                              <FormattedMessage id="PleaseSelectPackageFeatures" />
                            ),
                          }}
                          render={({ field: { onChange } }) => (
                            <Select
                              isRtl={true}
                              // menuIsOpen={true}
                              isSearchable={false}
                              className="basic-single"
                              classNamePrefix="select"
                              options={renderListFeatures()}
                              placeholder={intl.formatMessage({
                                id: "ChooseFeatures",
                              })}
                              isMulti={true}
                              onChange={(features) =>
                                onChange(features?.map((feature) => feature.id))
                              }
                            />
                          )}
                        />
                      </div>
                      {errors.features && (
                        <p className="error-hint">{errors.features.message}</p>
                      )}
                    </div>
                  </Col>
                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        <FormattedMessage id="addPackage" />
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPackage;
