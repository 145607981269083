import { NavLink, Outlet } from "react-router-dom";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

const Layout = (props) => {
  const { isLoggedIn } = useSelector((state) => state.authentication);

  return (
    <div className="main-wrapper">
      {isLoggedIn ? (
        <>
          <Header />
          <Sidebar />
          <div className="content-wrap" id="content-wrapper">
            {props.children}
            <Outlet />
          </div>
        </>
      ) : (
        <div className="content-wrap full-width">
          <div className="card login">
            <h3>
              {<FormattedMessage id="Please log in to access the required page."/>}
            </h3>
            <NavLink to="/login" className="btn">
              {<FormattedMessage id="Login"/>}
            </NavLink>
          </div>
        </div>
      )}
    </div>
  );
};
export default Layout;
