import React from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";

import { addCountry } from "store/actions";
import { useNavigate } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
const AddCountry = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    dispatch(addCountry({ data, navigate }));
  };
  const intl = useIntl();
  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>
              <FormattedMessage id="addNewCountry" />
            </h4>{" "}
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={10} md={12}>
                <Row>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>
                        {" "}
                        <FormattedMessage id="CountryNameInArabic" />{" "}
                      </h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder={intl.formatMessage({
                            id: "CountryNameInArabic",
                          })}
                          {...register("name[ar]", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.name?.["ar"]?.type === "required" && (
                          <FormattedMessage id="PleaseEnterTheCountryNameInArabic" />
                        )}
                      </p>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>
                        {" "}
                        <FormattedMessage id="CountryNameInEnglish" />{" "}
                      </h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder={intl.formatMessage({
                            id: "CountryNameInEnglish",
                          })}
                          {...register("name[en]", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.name?.["en"]?.type === "required" && (
                          <FormattedMessage id="PleaseEnterTheCountryNameInEnglish" />
                        )}
                      </p>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>
                        {" "}
                        <FormattedMessage id="CountryCode" />{" "}
                      </h5>{" "}
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder={intl.formatMessage({
                            id: "CountryCode",
                          })}
                          {...register("code", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.code?.type === "required" && (
                          <FormattedMessage id="PleaseEnterTheCountryCode" />
                        )}
                      </p>
                    </div>
                  </Col>
                  <Row />
                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        <FormattedMessage id="addCountry" />{" "}
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCountry;
