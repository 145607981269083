import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap";

import {
  editPaymentMethod,
  getFeatures,
  getSinglePaymentMethod,
} from "store/actions";
import { useNavigate, useParams } from "react-router-dom";
import server from "api/server";
import imageUpload from "assets/svgs/imageUpload.svg";
import Loader from "components/shared/Loader";
import { toast } from "react-toastify";
import { FormattedMessage, useIntl } from "react-intl";
import { getPublicImage } from "helpers/functions";
const EditPaymentMethod = () => {
  const [selectedImage, setSelectedImage] = useState({
    preview: null,
    path: null,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { formatMessage } = useIntl();

  const { singlepaymentMethod, loading } = useSelector(
    (state) => state?.paymentMethods
  );

  const { register, handleSubmit, reset, control, formState: { errors }, } = useForm();
const intl=useIntl()
  useEffect(() => {
    dispatch(getFeatures());
    dispatch(getSinglePaymentMethod(id));
  }, [dispatch, id]);

  useEffect(() => {
    reset({
      name: singlepaymentMethod?.name,
      image: singlepaymentMethod?.image,
    });
  }, [singlepaymentMethod, reset]);

  const imageChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      const { data } = await server().post("/upload", formData);
      setSelectedImage({
        preview: e.target.files[0],
        path: getPublicImage(data.data.files[0]),
      });
    }
  };

  const removeSelectedImage = () => {
    setSelectedImage();
  };

  const onSubmit = (data) => {
    if (selectedImage && selectedImage.path) data["image"] = selectedImage.path;

    if (!data?.image)
      return toast.error(formatMessage({ id: "pleaseSelectImage" }));

    // data.image = "uploads/" + data.image.split("/").pop();
    dispatch(editPaymentMethod({ id, data, navigate }));
  };

  if (loading) return <Loader />;
  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>
              <FormattedMessage id="editPaymentMethodDetails" />
            </h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={10} md={12}>
                <Row>
                  <Col lg={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="paymentMethodImage" />
                      </h5>
                      <div className="image-uploader image-contain">
                        <div className="img-pat">
                          <img
                            src={imageUpload}
                            width={46}
                            height={46}
                            alt=""
                          />
                        </div>
                        <label>
                          {selectedImage && (
                            <div>
                              <Controller
                                control={control}
                                name="image"
                                render={() => {
                                  return (
                                    <img
                                      src={
                                        selectedImage && selectedImage.preview
                                          ? URL.createObjectURL(
                                              selectedImage.preview
                                            )
                                          : singlepaymentMethod?.image
                                      }
                                      width={160}
                                      height={160}
                                      alt=""
                                    />
                                  );
                                }}
                              />

                              <button
                                className="removeImg"
                                onClick={removeSelectedImage}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="12"
                                  height="12"
                                  fill="currentColor"
                                  className="bi bi-x-lg"
                                  viewBox="0 0 16 16"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
                                  />
                                  <path
                                    fillRule="evenodd"
                                    d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
                                  />
                                </svg>
                              </button>
                            </div>
                          )}

                          <input
                            accept="image/*"
                            type="file"
                            onChange={imageChange}
                          />
                        </label>
                        <p className="error-hint">
                          {errors.image?.type === "required" && (
                            <formatMessage id="PleaseSelectTheLogoImage " />
                          )}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="PaymentMethodNameInArabic" />
                      </h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder={intl.formatMessage({ id:"PaymentMethodNameInArabic" })}

                          {...register("name[ar]", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.name?.["ar"]?.type === "required" &&
                         (<FormattedMessage id="PleaseEnterThePaymentMethodNameinArabic"/>)}
                      </p>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>
                    
                        <FormattedMessage id="PaymentMethodNameInEnglish" />
                      </h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder={intl.formatMessage({ id:  "PaymentMethodNameInEnglish" })}
                          {...register("name[en]", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.name?.["en"]?.type === "required" && (
                          <FormattedMessage id="PleaseEnterThePaymentMethodNameInEnglish" />
                        )}
                      </p>
                    </div>
                  </Col>

                  <input type="hidden" {...register("image")} />
                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                      <FormattedMessage id="SaveChanges" />
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditPaymentMethod;
