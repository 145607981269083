import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import {
  addPaymentMethodApi,
  deletePaymentMethodApi,
  editPaymentMethodApi,
  getPaymentMethodsApi,
  getSinglePaymentMethodApi,
} from "api/paymentMethods";

// Login Redux States
import {
  ADD_PAYMENT_METHOD,
  DELETE_PAYMENT_METHOD,
  EDIT_PAYMENT_METHOD,
  GET_PAYMENT_METHODS,
  GET_SINGLE_PAYMENT_METHOD,
} from "./actionTypes";

import {
  addPaymentMethodFailure,
  addPaymentMethodSuccess,
  deletePaymentMethodFailure,
  deletePaymentMethodSuccess,
  editPaymentMethodFailure,
  editPaymentMethodSuccess,
  getPaymentMethodsFailure,
  getPaymentMethodsSuccess,
  getSinglePaymentMethodFailure,
  getSinglePaymentMethodSuccess,
} from "./actions";
import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";

function* getPaymentMethods() {
  try {
    const { data } = yield call(getPaymentMethodsApi);
    yield put(getPaymentMethodsSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(
      getPaymentMethodsFailure(error?.response?.data?.errors?.[0]?.msg)
    );
  }
}

function* getSinglePaymentMethod({ payload }) {
  try {
    const { data } = yield call(getSinglePaymentMethodApi, payload);
    yield put(getSinglePaymentMethodSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getSinglePaymentMethodFailure(error?.response?.data || error));
  }
}

function* addPaymentMethod({ payload }) {
  try {
    const { data } = yield call(addPaymentMethodApi, payload.data);
    yield put(addPaymentMethodSuccess(data));
    yield payload.navigate("/paymentmethods");
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data || error });

    yield put(addPaymentMethodFailure(error.response?.data?.message || error));
  }
}

function* editPaymentMethod({ payload }) {
  try {
    const { data } = yield call(editPaymentMethodApi, payload);
    yield put(editPaymentMethodSuccess(data));
    yield payload.navigate("/paymentmethods");
  } catch (error) {
    console.log(error);
    toastErrorMessage({ error: error?.response?.data || error });
    yield put(editPaymentMethodFailure(error?.response?.data || error));
  }
}

function* deletePaymentMethod({ payload }) {
  try {
    yield call(deletePaymentMethodApi, payload);
    yield put(deletePaymentMethodSuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(deletePaymentMethodFailure(error?.response?.data || error));
  }
}

export function* watchGetPaymentMethods() {
  yield takeEvery(GET_PAYMENT_METHODS, getPaymentMethods);
}

export function* watchSinglePaymentMethod() {
  yield takeEvery(GET_SINGLE_PAYMENT_METHOD, getSinglePaymentMethod);
}

export function* watchAddPaymentMethod() {
  yield takeEvery(ADD_PAYMENT_METHOD, addPaymentMethod);
}

export function* watchEditPaymentMethod() {
  yield takeEvery(EDIT_PAYMENT_METHOD, editPaymentMethod);
}

export function* watchDeletePaymentMethod() {
  yield takeEvery(DELETE_PAYMENT_METHOD, deletePaymentMethod);
}

function* paymentMethodsSaga() {
  yield all([fork(watchGetPaymentMethods)]);
  yield all([fork(watchSinglePaymentMethod)]);
  yield all([fork(watchAddPaymentMethod)]);
  yield all([fork(watchEditPaymentMethod)]);
  yield all([fork(watchDeletePaymentMethod)]);
}

export default paymentMethodsSaga;
