import ControlArea from "components/Layout/ControlArea";
import React from "react";
import { useSelector } from "react-redux";
import themesIcon from "assets/svgs/themes.svg";
import Table from "./Table";
import { useSearchParams } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
const Index = () => {
  const { pages } = useSelector((state) => state.pages);
  const [searchParams] = useSearchParams();

  const deviceType = searchParams.get("deviceType");

  return (
    <>
      <div className="stores-wrap">
        <ControlArea
          btnTxt={<FormattedMessage id="Add Page"/>}
          cardTxt={<FormattedMessage id="pages"/>}
          icon={themesIcon}
          url={`/themes/pages/add?deviceType=${deviceType}`}
        />
        <Table data={pages} />
      </div>
    </>
  );
};

export default Index;
