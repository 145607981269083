import ControlArea from "components/Layout/ControlArea";
import React from "react";
import { useSelector } from "react-redux";
import citiesIcon from "assets/svgs/cities.svg";
import Table from "./Table";
import { FormattedMessage } from "react-intl";
const Index = () => {
  const { cities } = useSelector((state) => state.cities);
  return (
    <>
      <div className="stores-wrap">
        <ControlArea
         
          btnTxt={<FormattedMessage id="cities" />}
          cardTxt={<FormattedMessage id="addCity" />}
          icon={citiesIcon}
          url="/cities/addcity"
        />
        <Table data={cities} />
      </div>
    </>
  );
};

export default Index;
