import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { Dropdown } from "react-bootstrap";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { Context } from "wrapper";
import LangIcon from "assets/svgs/globe.svg";
const languageMap = {
  ar: { label: "العربية", dir: "rtl", active: true },
  en: { label: "English", dir: "ltr", active: false },
};

const LanguageSwitch = () => {
  const { setLocale } = useContext(Context);
  const selected = localStorage.getItem("i18nextLng") || "ar";
  const { t } = useTranslation();

  const [menuAnchor, setMenuAnchor] = useState(null);
  useEffect(() => {
    document.body.dir = languageMap[selected]?.dir;
  }, [menuAnchor, selected, t]);

  return (
    <div className="lang-switcher">
      <Dropdown>
        <Dropdown.Toggle>
          <img src={LangIcon} alt="" className="logo-icon" />
          {languageMap[selected]?.label}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {Object.keys(languageMap)?.map((item) => (
            <DropdownItem
              button
              key={item}
              onClick={() => {
                i18next.changeLanguage(item);
                setLocale(item);
                setMenuAnchor(null);
              }}
            >
              {languageMap[item]?.label}
            </DropdownItem>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default LanguageSwitch;
