import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { editPortalFaq, getSinglePortalFaq } from "store/actions";
import { useNavigate, useParams } from "react-router-dom";
import StickyLoader from "components/shared/StickyLoader";
import { FormattedMessage } from "react-intl";
import { Editor } from "components/shared/Editor";

const EditFaq = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const { singlePortalFaq, loading } = useSelector(
    (state) => state?.portalFaqs
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm();

  useEffect(() => {
    dispatch(getSinglePortalFaq(id));
   
  }, [dispatch, id]);

  useEffect(() => {
    reset(singlePortalFaq);
  }, [reset, singlePortalFaq]);

  const onSubmit = (data) => {
    dispatch(editPortalFaq({ id, data, navigate }));
    
  };

  return (
    <>
      {loading && <StickyLoader fill="#346bf4" />}
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>
              <FormattedMessage id="editFaq" />
            </h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={10} md={12}>
                <Row>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="blogTitle" />
                      </h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          {...register("title", { required: true })}
                        />
                      </div>
                    </div>
                  </Col>

                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="faqReply" />
                      </h5>
                      <div>
                      
                        <Controller
                          control={control}
                          name="reply"
                          render={({ field: { value, onChange } }) => (
                     
                            <Editor
                              initialValue={singlePortalFaq?.reply}
                              onChange={(e, editor) => {
                                const data = editor.getContent();
                                onChange(data);
                              }}
                              tinymceScriptSrc="https://cdn.tiny.cloud/1/qagffr3pkuv17a8on1afax661irst1hbr4e6tbv888sz91jc/tinymce/5-stable/tinymce.min.js"
                            />
                          )}
                        />
                        <p className="error-hint">
                          {errors?.reply?.type === "required" && (
                            <FormattedMessage id="required" />
                          )}
                        </p>
                      </div>
                    </div>
                  </Col>

                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                      <FormattedMessage id="SaveChanges" />
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditFaq;
