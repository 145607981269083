import server from "./server";

export const getPortalTechnicalSupportsApi = async ({page,limit}) => {
  const response = await server().get(`/portalHelpTechnicalSupports?page=${page}&limit=${limit}`);
  return response.data;
};

export const getSinglePortalTechnicalSupportApi = async (id) => {
  const response = await server().get(`/portalHelpTechnicalSupports/${id}`);
  return response.data;
};

export const addPortalTechnicalSupportApi = async (payload) => {
  const response = await server().post("/portalHelpTechnicalSupports", payload);
  return response.data;
};

export const editPortalTechnicalSupportApi = async ({ id, data }) => {
  const response = await server().put(
    `/portalHelpTechnicalSupports/${id}`,
    data
  );
  return response.data;
};

export const deletePortalTechnicalSupportApi = async (id) => {
  const response = await server().delete(`/portalHelpTechnicalSupports/${id}`);
  return response.data;
};
