import { onKeyDownPreventSpace } from "helpers/functions";
import React from "react";
import { Accordion } from "react-bootstrap";
const TextAreaActions = ({ currentInput, componentData, setComponentData }) => {
  const handleInputChange = (e) => {
    const newComponentData = componentData?.map((component) => {
      if (component.id !== currentInput.id) return component;
      return {
        ...component,
        props: {
          ...component.props,
          [e.target.name]: e.target.value,
        },
      };
    });

    setComponentData(newComponentData);
  };

  const handleCheckedInputChange = (e) => {
    const newComponentData = componentData?.map((component) => {
      if (component.id !== currentInput.id) return component;
      return {
        ...component,
        props: {
          ...component.props,
          [e.target.name]: e.target.checked,
        },
      };
    });

    setComponentData(newComponentData);
  };

  return (
    <div className="accordion-wrapper">
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>الاعدادات الاساسية</Accordion.Header>
          <Accordion.Body>
            <div className="sidebar-form-wrapper">
              <label className="form-label">عنوان الحقل (label)</label>
              <div className="form-group">
                <div className="input-holder">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="عنوان الحقل (label)"
                    value={currentInput?.props?.label || ""}
                    onChange={handleInputChange}
                    name="label"
                  />
                </div>
              </div>
            </div>
            <div className="sidebar-form-wrapper">
              <label className="form-label">عنوان توضيحي (Placeholder)</label>
              <div className="form-group">
                <div className="input-holder">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="عنوان الحقل (label)"
                    value={currentInput?.props?.placeholder || ""}
                    onChange={handleInputChange}
                    name="placeholder"
                  />
                </div>
              </div>
            </div>

            <div className="sidebar-form-wrapper">
              <label className="form-label"> اعلي قيمة (max)</label>
              <div className="form-group">
                <div className="input-holder">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="اعلي قيمة (max)"
                    value={currentInput?.props?.max || ""}
                    onChange={handleInputChange}
                    name="max"
                  />
                </div>
              </div>
            </div>
            <div className="sidebar-form-wrapper">
              <label className="form-label"> اقل قيمة (min)</label>
              <div className="form-group">
                <div className="input-holder">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="اقل قيمة (min)"
                    value={currentInput?.props?.min || ""}
                    onChange={handleInputChange} 
                    name="min"
                  />
                </div>
              </div>
            </div>

            <div className="sidebar-form-wrapper">
              <label className="form-label"> اسم الحقل (name)</label>
              <div className="form-group">
                <div className="input-holder">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="اسم الحقل (name)"
                    value={currentInput?.props?.name || ""}
                    onChange={handleInputChange}
                    name="name"
                  />
                </div>
              </div>
            </div>
            <div className="sidebar-form-wrapper">
              <label className="form-label"> عنوان الحقل (title)</label>
              <div className="form-group">
                <div className="input-holder">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="عنوان الحقل (title)"
                    value={currentInput?.props?.title || ""}
                    onChange={handleInputChange}
                    name="title"
                  />
                </div>
              </div>
            </div>

            <div className="sidebar-form-wrapper">
              <label className="form-label"> التفاف النص (wrap)</label>
              <div className="form-group">
                <div className="input-holder">
                  <select
                    className="form-control form-select"
                    name="wrap"
                    onChange={handleInputChange}
                    value={currentInput?.props?.wrap}
                  >
                    <option value="soft">soft</option>
                    <option value="hard">hard</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="sidebar-form-wrapper">
              <label className="form-label"> اسم الدليل (dirname)</label>
              <div className="form-group">
                <div className="input-holder">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="اسم الدليل (dirname)"
                    value={currentInput?.props?.dirname || ""}
                    onChange={handleInputChange}
                    name="dirname"
                  />
                </div>
              </div>
            </div>
            <div className="sidebar-form-wrapper">
              <label className="form-label"> رمز الحقل (ID)</label>
              <div className="form-group">
                <div className="input-holder">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="رمز الحقل (ID)"
                    value={currentInput?.props?.id || ""}
                    onChange={handleInputChange}
                    name="id"
                    {...onKeyDownPreventSpace}
                  />
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>الاعدادات الاضافية</Accordion.Header>
          <Accordion.Body>
            <div className="sidebar-form-wrapper">
              <div className="form-group">
                <div className="label-holder">
                  <label>
                    <input
                      type="checkbox"
                      onChange={handleCheckedInputChange}
                      name="required"
                      defaultChecked={currentInput?.props?.required}
                    />
                    <span>مطلوب (required)</span>
                  </label>
                </div>
              </div>
            </div>

            <div className="sidebar-form-wrapper">
              <div className="form-group">
                <div className="label-holder">
                  <label>
                    <input
                      type="checkbox"
                      onChange={handleCheckedInputChange}
                      name="disabled"
                      defaultChecked={currentInput?.props?.disabled}
                    />
                    <span>معطل (disabled)</span>
                  </label>
                </div>
              </div>
            </div>

            <div className="sidebar-form-wrapper">
              <div className="form-group">
                <div className="label-holder">
                  <label>
                    <input
                      type="checkbox"
                      onChange={handleCheckedInputChange}
                      name="readOnly"
                      defaultChecked={currentInput?.props?.readOnly}
                    />
                    <span>قراءة فقط (readOnly)</span>
                  </label>
                </div>
              </div>
            </div>

            <div className="sidebar-form-wrapper">
              <div className="form-group">
                <div className="label-holder">
                  <label>
                    <input
                      type="checkbox"
                      onChange={handleCheckedInputChange}
                      name="spellCheck"
                      defaultChecked={currentInput?.props?.spellCheck}
                    />
                    <span> التدقيق الإملائي (spellCheck)</span>
                  </label>
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default TextAreaActions;
