import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap";

import { editCity, getCountries, getSingleCity } from "store/actions";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/shared/Loader";
import Select from "react-select";
import { FormattedMessage, useIntl } from "react-intl";
const EditCity = () => {
  const { locale } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { countries } = useSelector((state) => state?.countries);
  const { singleCity, loading } = useSelector((state) => state?.cities);
  const { register, handleSubmit, reset, control , formState: { errors }} = useForm();
  const intl = useIntl();

  useEffect(() => {
    dispatch(getCountries());
    dispatch(getSingleCity(id));
  }, [dispatch, id]);

  useEffect(() => {
    reset({
      name: singleCity?.name,
      country: singleCity?.country,
    });
  }, [reset, singleCity]);

  const onSubmit = (data) => {
    dispatch(editCity({ id, data, navigate }));
  };

  const renderListCountries = () => {
    return countries?.map((country) => ({
      label: country.name?.[locale],
      value: country?.name?.[locale],
      id: country?.id,
    }));
  };

  if (loading) return <Loader />;
  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>
              <FormattedMessage id="EditCityInformation" />
            </h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={10} md={12}>
                <Row>
                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="CountryName" />
                      </h5>
                      <div>
                        <Controller
                          control={control}
                          name="country"
                          render={({
                            field: { onChange, value, selected },
                          }) => (
                            <Select
                              isRtl={true}
                              isSearchable={false}
                              className="basic-single"
                              classNamePrefix="select"
                              options={renderListCountries()}
                              placeholder={intl.formatMessage({
                                id: "ChooseTheCountry",
                              })}
                              isMulti={false}
                              defaultValue={renderListCountries()?.find(
                                (value) => value?.id === singleCity?.country
                              )}
                              onChange={(selected) => {
                                onChange(selected?.id);
                              }}
                            />
                          )}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.country?.type === "required" && (
                          <FormattedMessage id="Please choose the country name" />
                        )}
                      </p>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="CityNameInArabic" />
                      </h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder={intl.formatMessage({
                            id: "CityNameInArabic",
                          })}
                          {...register("name[ar]", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.name?.["ar"]?.type === "required" && (
                          <FormattedMessage id="PleaseEnterTheCityNameInArabic" />
                        )}
                      </p>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="CityNameInEnglish" />
                      </h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder={intl.formatMessage({
                            id: "CityNameInEnglish",
                          })}
                          {...register("name[en]", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.name?.["en"]?.type === "required" && (
                          <FormattedMessage id="PleaseEnterTheCityNameInEnglish" />
                        )}
                      </p>
                    </div>
                  </Col>
                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        <FormattedMessage id="SaveChanges" />
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditCity;
