import server from "./server";

export const getItemsApi = async ({ dataKey }) => {
  const response = await server().get(`/${dataKey}`);
  return response.data;
};

export const getSingleItemApi = async ({ dataKey, id }) => {
  const response = await server().get(`/${dataKey}/${id}`);
  return response.data;
};

export const addItemApi = async ({ dataKey, data }) => {
  const response = await server().post(`/${dataKey}`, data);
  return response.data;
};

export const editItemApi = async ({ dataKey, id, data }) => {
  const response = await server().put(`/${dataKey}/${id}`, data);
  return response.data;
};

export const toggleActiveUserApi = async ({ id }) => {
  const response = await server().post(
    `auth/superAdmins/activationAccount/${id}`
  );
  return response.data;
};

export const deleteItemApi = async ({ dataKey, id }) => {
  const response = await server().delete(`/${dataKey}/${id}`);
  return response.data;
};
