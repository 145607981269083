import {
  GET_BLOGS,
  GET_BLOGS_SUCCESS,
  GET_BLOGS_FAILURE,
  ADD_BLOG,
  ADD_BLOG_SUCCESS,
  ADD_BLOG_FAILURE,
  GET_SINGLE_BLOG,
  GET_SINGLE_BLOG_SUCCESS,
  GET_SINGLE_BLOG_FAILURE,
  EDIT_BLOG,
  EDIT_BLOG_SUCCESS,
  EDIT_BLOG_FAILURE,
  DELETE_BLOG,
  DELETE_BLOG_SUCCESS,
  DELETE_BLOG_FAILURE,
  GET_TAGS,
  GET_TAGS_SUCCESS,
  GET_TAGS_FAILURE,
} from "./actionTypes";

export const getBlogs = (payload) => {
  return {
    type: GET_BLOGS,
    payload,
  };
};

export const getBlogsSuccess = (payload) => {
  return {
    type: GET_BLOGS_SUCCESS,
    payload,
  };
};

export const getBlogsFailure = (payload) => {
  return {
    type: GET_BLOGS_FAILURE,
    payload,
  };
};

export const getSingleBlog = (id) => {
  return {
    type: GET_SINGLE_BLOG,
    payload: id,
  };
};

export const getSingleBlogsuccess = (blog) => {
  return {
    type: GET_SINGLE_BLOG_SUCCESS,
    payload: blog,
  };
};

export const getSingleBlogFailure = (error) => {
  return {
    type: GET_SINGLE_BLOG_FAILURE,
    payload: error,
  };
};

export const addBlog = (payload) => {
  return {
    type: ADD_BLOG,
    payload,
  };
};

export const addBlogsuccess = (payload) => {
  return {
    type: ADD_BLOG_SUCCESS,
    payload,
  };
};

export const addBlogFailure = (error) => {
  return {
    type: ADD_BLOG_FAILURE,
    payload: error,
  };
};

export const editBlog = (payload) => {
  return {
    type: EDIT_BLOG,
    payload,
  };
};

export const editBlogsuccess = (payload) => {
  return {
    type: EDIT_BLOG_SUCCESS,
    payload,
  };
};

export const editBlogFailure = (error) => {
  return {
    type: EDIT_BLOG_FAILURE,
    payload: error,
  };
};

export const deleteBlog = (id) => {
  return {
    type: DELETE_BLOG,
    payload: id,
  };
};

export const deleteBlogsuccess = (id) => {
  return {
    type: DELETE_BLOG_SUCCESS,
    payload: id,
  };
};

export const deleteBlogFailure = (error) => {
  return {
    type: DELETE_BLOG_FAILURE,
    payload: error,
  };
};

export const getTags = () => {
  return {
    type: GET_TAGS,
  };
};

export const getTagsSuccess = (blogTags) => {
  return {
    type: GET_TAGS_SUCCESS,
    payload: blogTags,
  };
};

export const getTagsFailure = (error) => {
  return {
    type: GET_TAGS_FAILURE,
    payload: error,
  };
};
