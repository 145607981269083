import ControlArea from "components/Layout/ControlArea";
import React from "react";
import { useSelector } from "react-redux";
import categoriesIcon from "assets/svgs/categories.svg";
import Table from "./Table";
import { FormattedMessage } from "react-intl";
const Index = () => {
  const { categories } = useSelector((state) => state.categories);
  return (
    <>
      <div className="stores-wrap">
        <ControlArea
          btnTxt={<FormattedMessage id="addCategory" />}
          cardTxt={<FormattedMessage id="category" />}
          icon={categoriesIcon}
          url="/categories/addcategory"
        />
        <Table data={categories} />
      </div>
    </>
  );
};

export default Index;
