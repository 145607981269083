import ControlArea from "components/Layout/ControlArea";
import React from "react";
import { useSelector } from "react-redux";
import countriesIcon from "assets/svgs/countries.svg";
import Table from "./Table";
import { FormattedMessage } from "react-intl";
const Index = () => {
  const { countries } = useSelector((state) => state.countries);
  return (
    <>
      <div className="stores-wrap">
        <ControlArea
          btnTxt={<FormattedMessage id="addNewCountry" />}
          cardTxt={<FormattedMessage id="allCountries" />}
          icon={countriesIcon}
          url="/countries/addcountry"
        />
        <Table data={countries} />
      </div>
    </>
  );
};

export default Index;
