import ControlArea from "components/Layout/ControlArea";
import React, { useEffect, useState } from "react";
import themesIcon from "assets/svgs/themes.svg";
import Table from "./Table";
import { FormattedMessage } from "react-intl";

import { useLocation } from "react-router-dom";
import { PaginationControl } from "react-bootstrap-pagination-control";
import { useSelector, useDispatch } from "react-redux";
import {  useSearchParams } from "react-router-dom";

import { getThemesComponentsTypes } from "store/actions";

const Index = () => {
  const dispatch = useDispatch();

  const { componentsTypes, metadata } = useSelector(
    (state) => state.componentsTypes
  );
  const location = useLocation();
 const [searchParams] = useSearchParams();
  const queryParams = new URLSearchParams(location.search);
  const pageNumberFromQuery = parseInt(queryParams.get("page")) || 1;
  const pageSizeFromQuery = parseInt(queryParams.get("limit")) || 10;
  const [page, setPage] = useState(pageNumberFromQuery);
  const [limit, setLimit] = useState(pageSizeFromQuery);
 
  const deviceType = searchParams.get("deviceType");
  useEffect(() => {
    dispatch(getThemesComponentsTypes({deviceType, page, limit}));

    // Update URL with pageNumber and pageSize
    const newUrl = new URL(window.location.href);
    newUrl.searchParams.set("page", page);
    newUrl.searchParams.set("limit", limit);
    window.history.replaceState({}, "", newUrl);
  }, [dispatch, page, limit]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (e) => {
    setLimit(Number(e.target.value));
  };

 

  return (
    <>
      <div className="stores-wrap">
        <ControlArea
          btnTxt={<FormattedMessage id="Add element type" />}
          cardTxt={<FormattedMessage id="Types of elements" />}
          icon={themesIcon}
          url={`/themes/componentTypes/add?deviceType=${deviceType}`}
        />
        <Table data={componentsTypes} />
        <div className="table--pagination">
          <PaginationControl
            page={page}
            between={2}
            total={metadata?.totalResults || 1}
            limit={metadata?.pagination?.limit || 1}
            changePage={handlePageChange}
            ellipsis={2}
          />
          <select
            className="form-control form-select"
            value={limit}
            onChange={handlePageSizeChange}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </div>
      </div>
    </>
  );
};

export default Index;
