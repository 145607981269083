import server from "./server";

export const getAllSubscribersApi = async ({ page , limit}) => {
  const response = await server().get(`/portalSubscribers?page=${page}&limit=${limit}`);
  return response.data;
};

export const deleteSubscriberApi = async (id) => {
  const response = await server().delete(`/portalSubscribers/${id}`);
  return response.data;
};

