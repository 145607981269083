import React from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";

import { addUnit } from "store/actions";
import { useNavigate } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
const AddUnit = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    dispatch(addUnit({ data, navigate }));
  };

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>
              <FormattedMessage id="addNewUnit" />{" "}
            </h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={10} md={12}>
                <Row>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="unitNameArabic" />
                      </h5>{" "}
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder={intl.formatMessage({
                            id: "unitNameArabic",
                          })}
                          {...register("name[ar]", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.name?.["ar"]?.type === "required" && (
                          <FormattedMessage id="enterUnitNameArabic" />
                        )}
                      </p>
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>
                        <FormattedMessage id="unitNameEnglish" />
                      </h5>{" "}
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder={intl.formatMessage({
                            id: "unitNameEnglish",
                          })}
                          {...register("name[en]", { required: true })}
                        />
                      </div>
                      <p className="error-hint">
                        {errors?.name?.["en"]?.type === "required" && (
                          <FormattedMessage id="enterUnitNameEnglish" />
                        )}
                      </p>
                    </div>
                  </Col>

                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        <FormattedMessage id="addUnit" />{" "}
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddUnit;
