import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Col, Row } from "react-bootstrap";

import {
  editThemesComponentsType,
  getSingleThemesComponentsType,
  getThemesPages,
} from "store/actions";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import Loader from "components/shared/Loader";
import { getId } from "helpers/functions";
import { useIntl ,FormattedMessage } from "react-intl";
const EditTheme = () => {
  // const { templates } = useSelector((state) => state?.templates);
  const { pages } = useSelector((state) => state.pages);
  const { formatMessage } = useIntl();
  const { locale } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const deviceType = searchParams.get("deviceType");

  const { singleComponentsType, loading } = useSelector(
    (state) => state?.componentsTypes
  );

  const { register, handleSubmit, reset, setValue, watch , formState: { errors }, } = useForm();

  const [pagesToggleButtonText, setPagesToggleButtonText] =
    useState(formatMessage({id:"Select All"}));
  const [selectedPages, setSelectedPages] = useState(
    singleComponentsType?.pages || []
  );

  useEffect(() => {
    dispatch(getSingleThemesComponentsType(id));
    dispatch(getThemesPages(deviceType));
  }, [dispatch, id, deviceType]);

  useEffect(() => {
    reset({
      ...singleComponentsType,
    });
    setSelectedPages(singleComponentsType?.pages);

    return () => reset({ pages: "" });
  }, [singleComponentsType, reset]);

  useEffect(() => {
    if (selectedPages?.length === pages?.length)
      return setPagesToggleButtonText(formatMessage({ id: "Not Select All" }));
    setPagesToggleButtonText(formatMessage({ id: "Select All" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("pages"), singleComponentsType]);

  const onSubmit = (data) => {
    if (!data?.pages) data.pages = [];
    // data.pages = selectedPages;

    dispatch(editThemesComponentsType({ id, data, navigate, deviceType }));
  };

  const renderPages = pages?.map((page, index) => (
    <Col md="4" lg="3" xs="12" key={index}>
      <label
        className={`form-group ${
          selectedPages?.includes(getId(page)) ? "active" : ""
        }`}
      >
        <h5>
          <input
            type="checkbox"
            {...register("pages[]", {
              onChange: ({ target: { value } }) =>
                setSelectedPages((prev) => {
                  return prev?.includes(value)
                    ? prev?.filter((e) => e !== value)
                    : [...prev, value];
                }),
            })}
            value={getId(page)}
          />
          {page?.name?.[locale]}
        </h5>
      </label>
    </Col>
  ));
  if (loading) return <Loader />;

  const handleToggleSelect = () => {
    if (selectedPages?.length === pages?.length) {
      setValue("pages", []);
      setSelectedPages([]);
    } else {
      setValue(
        "pages",
        pages?.map((p) => getId(p))
      );
      setSelectedPages(pages?.map((p) => getId(p)));
    }
  };

  return (
    <div className="acc-form component-type">
      <div className="card">
        <div className="card-head">
          <h4>{<FormattedMessage id="Edit element type data" />}</h4>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Col xl={10} md={12}>
              <Row>
                <Col lg={6} xs={12}>
                  <div className="form-group required">
                    <h5>
                      {<FormattedMessage id="Element type name in Arabic" />}
                    </h5>
                    <div>
                      <input
                        type="text"
                        className="form-control form-outline"
                        placeholder={formatMessage({
                          id: "Element type name in Arabic",
                        })}
                        {...register("name[ar]", { required: true })}
                      />
                    </div>
                    <p className="error-hint">
                      {errors?.name?.["ar"]?.type === "required" &&
                         <FormattedMessage id="Please enter the element type name in Arabic"/>}
                    </p>
                  </div>
                </Col>
                <Col lg={6} xs={12}>
                  <div className="form-group required">
                  <h5>
                      {<FormattedMessage id= "Element type name in English"/>}
                    </h5>
                    <div>
                      <input
                        type="text"
                        className="form-control form-outline"
                        placeholder={formatMessage({id:"Element type name in English"})}
                        {...register("name[en]", { required: true })}
                      />
                    </div>
                    <p className="error-hint">
                      {errors?.name?.["en"]?.type === "required" &&
                        <FormattedMessage id="Please enter the element type name in English"/>}
                    </p>
                  </div>
                </Col>
                <Col xs={12}>
                  <div
                    className="form-group pages-select-wrap"
                    // style={{ maxHeight: "60vh", overflow: "auto" }}
                  >
                    <h5>
                      <button
                        type="button"
                        className=""
                        onClick={handleToggleSelect}
                      >
                        {pagesToggleButtonText}
                      </button>
                      اختر الصفحات
                    </h5>
                    <Row>{renderPages}</Row>
                  </div>
                </Col>
              </Row>
              <Col lg={4} xs={12}>
                <div className="form-group">
                  <button type="submit" className="btn btn-blue">
                  {<FormattedMessage id="Save Edits"/>}
                  </button>
                </div>
              </Col>
            </Col>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditTheme;
